import * as Yup from 'yup';
import { useRef, useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Box,
  Button,
  Link,
  Stack,
  IconButton,
  InputAdornment,
  Typography,
  Alert,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import useFetch from '../../../hooks/useFetch';
import Iconify from '../../../components/iconify';
import { FormProvider, RHFTextField, RHFCheckbox, RHFFile } from '../../../components/hook-form';
import Label from '../../../components/label';
// api
import axios from '../../../api/axios';

// context

// ----------------------------------------------------------------------

export default function CategoryForm({ parent }) {
  const { data: Categories, loading: loagindCategories, error: errorCategories } = useFetch('/api/marque');

  console.log(Categories)

  const [response, setResponse] = useState(null);

  const [error, setError] = useState(null);

  const [open, setOpen] = useState(false);

  const [openEdit, setOpenEdit] = useState(false);

  const [openRelation, setOpenRelation] = useState(false);

  const [del, setDel] = useState(false);

  const [edit, setEdit] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const [openShow, setOpenShow] = useState(false);

  const [openSnack, setOpenSnack] = useState(false);

  const [errorSupp, setErrorSupp] = useState(null);

  const [deletedItemId, setDeletedItemId] = useState(0);

  const [deletedItem, setDeletedItem] = useState(null);

  const [editCategory, setEditCategory] = useState('');

  const [editCategoryEnglish, setEditCategoryEnglish] = useState('');

  const [editCategoryArabic, setEditCategoryArabic] = useState('');

  const [image1, setImage1] = useState(null);

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };
  const handleCloseRelation = () => {
    setOpenRelation(false);
  };
  const handleCloseShow = () => {
    setOpenShow(false);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const handleSuppClick = () => {
    setDeletedItem(parent.name);
    setOpenDialog(true);
  };

  const handleDelClick = () => {
    setDeletedItem(parent.name);
    setOpenEdit(true);
  };

  const handleShowClick = () => {
    setDeletedItem(parent);
    setOpenShow(true);
  };

  const handleRelationClick = () => {
    setDeletedItem(parent);
    setOpenRelation(true);
  };

  const getAllCategories = (data) => {
    const cats = [];
    console.log(data);

    data?.forEach((cat) => {
      
        cats.push(cat);
      
    });

    return cats;
  };

  const [cmdFormFields, setCmdFormFields] = useState([
    {
      categorie: '',
    },
  ]);

  const addCmdFields = () => {
    const object = {
      categorie: '',
    };

    setCmdFormFields([...cmdFormFields, object]);
  };

  const removeCmdFields = (index) => {
    const data = [...cmdFormFields];
    data.splice(index, 1);
    setCmdFormFields(data);
  };

  const handleChange = (event, index) => {
    const data = [...cmdFormFields];
    // console.log(data[index][event.target.name]);
    data[index][event.target.name] = event.target.value;
    setCmdFormFields(data);
    console.log(cmdFormFields);
  };

  const onChangeCategory = (event, value) => {
    setEditCategory(event.target.value);
  };
  const onChangeCategoryEnglish = (event, value) => {
    setEditCategoryEnglish(event.target.value);
  };
  const onChangeCategoryArabic = (event, value) => {
    setEditCategoryArabic(event.target.value);
  };
  // const handleChangeCategory = (value) => {
  //   console.log(value)
  //   setEditCategory(value);
  // };

  const handleClickDelete = () => {
    if (parent.name === 'categorie') {
      setOpenSnack(true);
      setErrorSupp({});
    } else {
      console.log(parent.id);
      axios
        .delete(`api/category/${parent.id}`)
        .then()
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setOpenSnack(true);
          if (!error) {
            window.location.reload(false);
          }
        });
    }

    handleCloseDialog();
  };

  const clean = (obj) => {
    Object.keys(obj).forEach((propName) =>{
    
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    })
    return obj
  }


  const handleSubmitEdit = async () => {
    if (parent.name === 'categorie') {
      // setOpenSnack(true);
      // setErrorSupp({});
    } else {
      axios
        .put(`api/category/${parent.id}`, clean({ name: editCategory, name_english:editCategoryEnglish, name_arabic: editCategoryArabic }), { headers: { 'content-type': 'multipart/json' } })
        .then((res) => {
          console.log(res);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setOpenSnack(true);
          if (!error) {
            window.location.reload(false);
          }
        });
    }

    handleCloseEdit();
  };

const handleSubmitRelation = async ()=>{

  
  
    
    await cmdFormFields?.forEach((categorie)=>{

       axios
      .post('api/addMarques', 
      {
        categoryId:deletedItem.id,
        marques:categorie.categorie,
      }, { mode: 'no-cors' })
      .then((res) => {
        setResponse(res);
        setError(null);
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
        setResponse(null);
      })
      .finally(() => {
        setOpen(true);
      });

    }).then(()=>{
      window.location.reload(false);
    
    })
    
   
  
}


  const LoginSchema = Yup.object().shape({
    parent: Yup.string().required('Catégorie parent obligatoire (appuier sur une category pour remplir)'),
    category: Yup.string().required('Catégorie est obligatoire'),
    categoryEnglish: Yup.string().required('Catégorie est obligatoire'),
    categoryArabic: Yup.string().required('Catégorie est obligatoire'),
    image: Yup.mixed()
      .notRequired()
      // .test('fileExist', 'Image obligatoire', (value) => {
      //   return value && value?.name;
      // })
      .test('type', 'Image extension invalid', (value) => {
        return (
          !value?.name ||
          (value && (value.type === 'image/png' || value.type === 'image/jpeg' || value.type === 'image/svg+xml'))
        );
      })
      .test('fileSize', 'La taille de fichier est trop volumineux', (value) => {
        return !value?.name || (value && value.size <= 2000000);
      }),
  });

  const defaultValues = {
    parent: parent.name || 'categorie',
    category: '',
    categoryEnglish: '',
    categoryArabic: '',
    image: null,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (values) => {
    if (parent.name === 'categorie') {
      values.parent_id = 0;
    } else {
      values.parent_id = parent.id;
    }
    values.parent = parent;

    if (values.image != null) {
      // console.log(values.image)
      axios
        .post('api/categoryFile', { image: values.image }, { mode: 'no-cors' })
        .then((res) => {
          setResponse(res);
          setError(null);
          setImage1(res.data);
          values.image = res.data;
          // console.log(res.data);
          // window.location.reload(false);
        })
        .catch((error) => {
          setResponse(null);
          setError(error);
        })
        .finally(() => {
          console.log(values);
          // values.image = `img/category/${image1.name}`;
          axios
            .post(
              `api/category`,
              { name: values.category, name_english: values.categoryEnglish, name_arabic: values.categoryArabic, parent_id: values.parent_id, image: values.image },
              { mode: 'no-cors' }
            )
            .then((res) => {
              setResponse(res);
              setError(null);
              window.location.reload(false);
            })
            .catch((error) => {
              console.log(error);
              setResponse(null);
              setError(error);
            })
            .finally(() => {
              setOpen(true);
            });
        });
    } else {
      // axios
      //   .post(
      //     `api/category`,
      //     { name: values.category, parent_id: values.parent_id, image: values.image },
      //     { mode: 'no-cors' }
      //   )
      //   .then((res) => {
      //     setResponse(res);
      //     setError(null);
      //     window.location.reload(false);
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //     setResponse(null);
      //     setError(error);
      //   })
      //   .finally(() => {
      //     setOpen(true);
      //   });

      axios
        .post('api/category', { name: values.category, name_english: values.categoryEnglish, name_arabic: values.categoryArabic, parent_id: values.parent_id }, { mode: 'no-cors' })
        .then((res) => {
          setResponse(res);
          setError(null);
          window.location.reload(false);
        })
        .catch((error) => {
          setError(error);
          setResponse(null);
        })
        .finally(() => {
          setOpen(true);
        });
    }

    // axios
    //   .post('api/category', { name: values.category, parent_id: values.parent_id })
    //   .then((res) => {
    //     setResponse(res);
    //     setError(null);
    //     window.location.reload(false);
    //   })
    //   .catch((error) => {
    //     setError(error);
    //     setResponse(null);
    //   })
    //   .finally(() => {
    //     setOpen(true);
    //   });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {error ? (
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert variant="filled" onClose={handleClose} severity="error" sx={{ width: '100%' }}>
              catégorie existe déja.
            </Alert>
          </Snackbar>
        ) : (
          <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert variant="filled" onClose={handleClose} severity="success" sx={{ width: '100%' }}>
              Le catégorie a été ajouté avec succès.
            </Alert>
          </Snackbar>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <RHFTextField
            value={parent.name || 'categorie'}
            name="parent"
            label="Parent catégorie"
            InputProps={{
              readOnly: true,
            }}
          />

          {/* Delete catégory */}

          <IconButton aria-label="supprimer" color="error" onClick={handleSuppClick}>
            <Iconify icon="material-symbols:delete-forever" />
          </IconButton>

          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Supprimer la catégorie{' '}
              <Typography
                component="span"
                sx={{
                  color: 'red',
                }}
              >
                {deletedItem}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Veuillez vraiment supprimer cette catégorie
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog}>Annuler</Button>
              <Button onClick={handleClickDelete} autoFocus>
                Accepter
              </Button>
            </DialogActions>
          </Dialog>
          {errorSupp ? (
            <Snackbar
              open={openSnack}
              autoHideDuration={6000}
              onClose={handleCloseSnack}
              anchorOrigin={{ vertical: 'buttom', horizontal: 'right' }}
            >
              <Alert variant="filled" onClose={handleCloseSnack} severity="error" sx={{ width: '100%' }}>
                Erreur dans la suppression du catégorie
              </Alert>
            </Snackbar>
          ) : (
            <Snackbar
              open={openSnack}
              autoHideDuration={6000}
              onClose={handleCloseSnack}
              anchorOrigin={{ vertical: 'buttom', horizontal: 'right' }}
            >
              <Alert variant="filled" onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                La catégorie a été suprimer avec succès.
              </Alert>
            </Snackbar>
          )}

          {/* Edit category */}

          <IconButton aria-label="modifier" color="warning" onClick={handleDelClick}>
            <Iconify icon="material-symbols:edit" />
          </IconButton>

          <Dialog open={openEdit} onClose={handleCloseEdit}>
            <DialogTitle>
              Modifier la catégorie{' '}
              <Typography
                component="span"
                sx={{
                  color: 'red',
                }}
              >
                {deletedItem}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="edit_category"
                label="Catégorie"
                type="text"
                fullWidth
                variant="standard"
                value={editCategory}
                onChange={onChangeCategory}
              />
              <TextField
                autoFocus
                margin="dense"
                id="edit_category"
                label="Catégorie anglais"
                type="text"
                fullWidth
                variant="standard"
                value={editCategoryEnglish}
                onChange={onChangeCategoryEnglish}
              />
              <TextField
              dir='rtl'
                autoFocus
                margin="dense"
                id="edit_category"
                label="Catégorie arabe"
                type="text"
                fullWidth
                variant="standard"
                value={editCategoryArabic}
                onChange={onChangeCategoryArabic}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseEdit}>Cancel</Button>
              <Button onClick={handleSubmitEdit}>Submit</Button>
            </DialogActions>
          </Dialog>

          <IconButton aria-label="modifier" color="primary" onClick={handleShowClick}>
            <Iconify icon="material-symbols:image" />
          </IconButton>

          <Dialog open={openShow} onClose={handleCloseShow}>
            <DialogTitle>
              <Typography>{deletedItem?.name} / {deletedItem?.name_english} / {deletedItem?.name_arabic}</Typography>
            </DialogTitle>
            <DialogContent>
              <img
                src={`https://lab.saroussa.com/${deletedItem?.image}`}
                alt="category"
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  width: 'auto',
                  height: 'auto',
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseShow}>Cancel</Button>
            </DialogActions>
          </Dialog>

          <IconButton aria-label="modifier" color="primary" onClick={handleRelationClick}>
            <Iconify icon={'fluent-mdl2:relationship'} sx={{ mr: 2 }} />
          </IconButton>

          <Dialog open={openRelation} onClose={handleCloseRelation}>
            <DialogTitle>
              <Typography>{deletedItem?.name}</Typography>
            </DialogTitle>
            <DialogContent sx={{ width: 500 }}>
              {cmdFormFields.map((form, index1) => {
                return (
                  <Box>
                    <Stack direction="row" alignItems="center" m={3}>
                      <div>Catégorie : </div>
                      <Select
                        id="categorie"
                        value={form.stock}
                        onChange={(event) => handleChange(event, index1)}
                        label="Catégorie"
                        name="categorie"
                        style={{ width: '50%' }}
                      >
                        {getAllCategories(Categories).map((category) => {
                          return <MenuItem value={category.id}>{category.name}</MenuItem>;
                        })}
                      </Select>
                      <Button
                        variant="contained"
                        color="warning"
                        style={{ margin: '7px' }}
                        state={{ from: 'occupation' }}
                        onClick={(event) => removeCmdFields(event, index1)}
                      >
                        Enlever
                      </Button>
                    </Stack>
                  </Box>
                );
              })}

              <Button variant="contained" state={{ from: 'occupation' }} onClick={addCmdFields}>
                Ajouter plus...
              </Button>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseRelation}>Cancel</Button>
              <Button onClick={handleSubmitRelation}>Submit</Button>
            </DialogActions>
          </Dialog>
        </Box>

        <RHFTextField name="category" label="catégorie" />
        <RHFTextField name="categoryEnglish" label="catégorie anglais" />
        <RHFTextField name="categoryArabic" label="catégorie arabe" dir='rtl'/>
        <RHFFile name="image" label="Image" />
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Ajouter
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
