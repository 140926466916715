import * as Yup from 'yup';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Stack,
  Snackbar,
  Alert,
  Box,
  CircularProgress,
  Typography,
  Autocomplete,
  TextField,
  MenuItem,
  Select,
  InputLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// api
import axios from '../../../api/axios';
// components
import { FormProvider, RHFTextField, RHFFile, RHFSelect } from '../../../components/hook-form';
// hooks
import useFetch from '../../../hooks/useFetch';

// ----------------------------------------------------------------------

const POSITION = ['centre', 'est', 'ouest', 'sud'];

export default function POSForm({ membre }) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [pos, setPos] = useState(membre?membre.position:'');
  //   const phoneRegExp =/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const phoneRegExp = '(0||\\+213)[1-9][0-9]{8}';
  const fixRegExp = '(023||\\021||\\+213)[0-9]{6}';
  // const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/
  const LoginSchema = Yup.object().shape({
    name: membre ? Yup.string() : Yup.string().required('Nom obligatoire'),
    adress: membre ? Yup.string() : Yup.string().required('Adresse obligatoire'),
    adress_arabic: membre ? Yup.string() : Yup.string().required('Adresse obligatoire'),
    adress_english: membre ? Yup.string() : Yup.string().required('Adresse obligatoire'),
    location: membre ? Yup.string() : Yup.string().required('Location obligatoire'),
    slogan: membre ? Yup.string() : Yup.string().required('Slogan obligatoire'),
    // position: membre ? Yup.string() : Yup.string().required('Position obligatoire'),

    lat: membre
      ? Yup.number()
          .nullable()
          .transform((_, val) => (val !== '' ? Number(val) : null))
      : Yup.number()
          .typeError('Latitude doit etre un numéro entre -90 et 90')
          .min(-90, "Latitude n'est pas valide")
          .max(90, "Latitude n'est pas valide")
          .required('Latitude obligatoire'),
    lon: membre
      ? Yup.number()
          .nullable()
          .transform((_, val) => (val !== '' ? Number(val) : null))
      : Yup.number()
          .typeError('Longtitude doit etre un numéro entre -180 et 180')
          .min(-180, "Longtitude n'est pas valide")
          .max(180, "Longtitude n'est pas valide")
          .required('Longtitude obligatoire'),
    mobile: membre
      ? Yup.string()
      : Yup.string()
          .required('Numéro de téléphone obligatoire')
          .matches(phoneRegExp, "Numéro de téléphone n'est pas valide"),
    mobile2: membre
      ? Yup.string()
      : Yup.string()
          .required('Numéro de téléphone obligatoire')
          .matches(phoneRegExp, "Numéro de téléphone n'est pas valide"),
    email: membre ? Yup.string() : Yup.string().required('Email obligatoire'),
  });

  const clean = (obj) => {
    Object.keys(obj).forEach((propName) => {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    });
    return obj;
  };

  const defaultValues = {
    name: membre ? membre.name : '',
    slogan: membre ? membre.slogan : '',
    adress: membre ? membre.adress : '',
    adress_arabic: membre ? membre.adress_arabic : '',
    adress_english: membre ? membre.adress_english : '',
    lat: membre ? membre.lat : '',
    lon: membre ? membre.lon : '',
    mobile: membre ? membre.mobile : '',
    mobile2: membre ? membre.mobile2 : '',
    email: membre ? membre.email : '',
    location: membre ? membre.location : '',
    // position: membre ? membre.position : '',
  };

  const handleChange = (event)=>{
    setPos(event.target.value)

  }
  const handleClose = () => {
    setOpen(false);
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (values) => {
    values.position=pos;
    // console.log(values.position)
    
    if (membre) {
      axios
        .put(`api/pos/${membre.id}`, clean(values), {
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        })
        .then((res) => {
          setResponse(res);
          setError(null);
          window.location.reload(false);
        })
        .catch((error) => {
          console.log(error);
          setError(error);
          setResponse(null);
        })
        .finally(() => {
          setOpen(true);
        });
    } else {
      axios
        .post('api/pos', values, { mode: 'no-cors' })
        .then((res) => {
          setResponse(res);
          setError(null);
          window.location.reload(false);
        })
        .catch((error) => {
          console.log(error);
          setError(error);
          setResponse(null);
        })
        .finally(() => {
          setOpen(true);
        });
    }
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {error ? (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert variant="filled" onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Magasin existe déja.
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert variant="filled" onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Le magasin a été ajouté avec succès.
          </Alert>
        </Snackbar>
      )}
      <Stack spacing={3}>
        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <RHFTextField name="name" label="Nom" />
          <RHFTextField name="slogan" label="Slogan" />
        </Stack>

        <RHFTextField name="adress" label="Adresse" />
        <RHFTextField name="adress_arabic" label="Adresse arabe" dir="rtl" />
        <RHFTextField name="adress_english" label="Adresse anglais" />
        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <RHFTextField name="lat" label="Latitude" />
          <RHFTextField name="lon" label="Longtitude" />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <RHFTextField name="mobile" label="Numéro de téléphone" />
          <RHFTextField name="mobile2" label="2émé numéro de téléphone" />
        </Stack>
        <RHFTextField name="email" label="Email" />
        <RHFTextField name="location" label="Location" />
        {/* <RHFTextField name="position" label="Position" /> */}
        {/* <RHFSelect options={[...POSITION]} name="position" label="Position" />  */}
        
         <InputLabel id="demo-simple-select-label">Position</InputLabel>
        <Select
          id="position"
          value={pos}
          onChange={(event) => handleChange(event)}
          label="Position"
          name="position"
         
        >
          {POSITION.map((position) => {
            return <MenuItem value={position}>{position}</MenuItem>;
          })}
        </Select> 
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Submit
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
