import { Navigate, Outlet, Link } from "react-router-dom";
import useAuthContext from "../context/AuthContext";
import DashboardLayout from "./dashboard/DashboardLayout"

const AuthLayout = () => {
  const { user, auth } = useAuthContext();
  // console.log(localStorage.getItem("user"));
  return localStorage.getItem("user") ? ( 
    <DashboardLayout/>
  ) : (
    <Navigate to="/login" />
  );
};

export default AuthLayout;
