import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import {
  Box, Card, Link, Typography, Stack, Collapse, IconButton, Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Snackbar,
  Alert
} from '@mui/material';
import { styled } from '@mui/material/styles';
// utils
import { fCurrency, fShortenNumber } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import Logo from '../../../components/logo';
import { ColorPreview } from '../../../components/color-utils';
import Iconify from '../../../components/iconify';
// api
import axios from '../../../api/axios';


// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
  open: PropTypes.bool,
};

export default function ShopProductCard({ product, openCollaps:open }) {
console.log(open);
  const { id, name, image, prix1, prix2, qty,qty_onhold:qtyOnhold, category, brand, modal } = product;

  const [openCollapse, setOpenCollapse] = useState(open);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [errorDel, setErrorDel] = useState(null);

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(`/products/edit/${id}`, { state: { name: id } });
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  }

  const handleOpenSnack = () => {
    setOpenSnack(true);
  }

  const handleCloseSnack = () => {
    setOpenSnack(false);
  }

  const handleClickDelete = () => {
    axios
      .delete(`api/product/${id}`)
      .then()
      .catch((error) => { setErrorDel(error) })
      .finally(() => {
        handleOpenSnack();
        if (!errorDel) {
          window.location.reload(false);
        }
      });
    handleCloseDialog();
  };

  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Label
            variant="filled"
            color={(qty <= 0 && 'error') || 'info'}
            sx={{
              zIndex: 9,
              top: 16,
              right: 16,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            {(qty-qtyOnhold <= 0 && 'Indisponible') || 'Disponible'}
          </Label>

        </Stack>


        {image ? <StyledProductImg alt={name} src={`https://lab.saroussa.com/${image}`} /> : <Logo />}
      </Box>

      <Stack spacing={0} sx={{ p: 1 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
        >
          <IconButton aria-label="supprimer" color="error" onClick={() => setOpenDialog(true)}>
            <Iconify icon="material-symbols:delete" />
          </IconButton>
          <IconButton aria-label="modifier" color="warning" onClick={(event) => handleNavigate(event)} >
            <Iconify icon="material-symbols:edit" />
          </IconButton>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Link color="inherit" underline="hover">
            <Typography variant="subtitle2" noWrap>
              {name}
            </Typography>
          </Link>

          <IconButton
            color="inherit"
            aria-label="expand row"
            size="small"
            onClick={() => setOpenCollapse(!openCollapse)}
          >
            {openCollapse ? <Iconify
              icon="ic:baseline-chevron-right"

            /> : <Iconify
              icon="ic:outline-expand-more"

            />}
          </IconButton>
        </Stack>
        <Collapse in={openCollapse} timeout="auto" unmountOnExit>
          <Typography variant="subtitle2" noWrap>
            Quantité: {qty}
          </Typography>

          <Stack direction="row" alignItems="center" justifyContent="space-between">
            {/* <ColorPreview colors={colors} /> */}
            <Typography
              component="span"
              variant="body1"
            >
              Catégorie: {category.name}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-around">
            {/* <ColorPreview colors={colors} /> */}

            <Typography
              component="span"
              variant="body1"
              sx={{
                color: 'text.disabled',
                // textDecoration: 'line-through',
              }}
            >
              {brand.name}
            </Typography>
            <Typography variant="subtitle1">
              &nbsp;
              {modal.name}
            </Typography>
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between">
            {/* <ColorPreview colors={colors} /> */}
            <Typography variant="subtitle1">
              <Typography
                component="span"
                variant="body1"
                sx={{
                  color: 'text.disabled',
                  // textDecoration: 'line-through',
                }}
              >
                {prix1 && fCurrency(prix1)} DA
              </Typography>
              &nbsp;
              {fCurrency(prix1)} DA
            </Typography>
          </Stack>
        </Collapse>
      </Stack>
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Supprimer le produit <Typography
          component="span"
          sx={{
            color: 'red',
            fontWeight: 'bold'
          }}
        >
          {name}</Typography>  </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Veuillez vraiment supprimer ce produit
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialog()}>Annuler</Button>
          <Button onClick={() => handleClickDelete()} autoFocus>
            Accepter
          </Button>
        </DialogActions>
      </Dialog>
      {errorDel ? (
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: 'buttom', horizontal: 'right' }}
        >
          <Alert
            variant="filled"
            onClose={handleCloseSnack}
            severity="error"
            sx={{ width: '100%' }}
          >
            Erreur dans la suppression du produit {errorDel}
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: 'buttom', horizontal: 'right' }}
        >
          <Alert
            variant="filled"
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: '100%' }}
          >
            Le produit a été suprimer avec succès.
          </Alert>
        </Snackbar>
      )}
    </Card>
  );
}
