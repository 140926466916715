import PropTypes from 'prop-types';
import { useMemo, useState, createContext  } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import { ThemeProvider as MUIThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import { amber, deepOrange, grey } from '@mui/material/colors';
//
import palette from './palette';
import shadows from './shadows';
import typography from './typography';
import GlobalStyles from './globalStyles';
import customShadows from './customShadows';
import componentsOverride from './overrides';

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};
export const ColorModeContext = createContext({ toggleColorMode: () => {} });

export default function ThemeProvider({ children }) {
  const [mode, setMode] = useState('light');
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    []
  );

  const themeOptions = useMemo(
    () => ({
      palette: {
        ...palette,
        
      },
      shape: { borderRadius: 8 },
      typography,
      shadows,
      customShadows,
    }),
    [mode]
  );
  const themeOptionsDark = useMemo(
    () => ({
      palette: {
        mode:'dark',
        background: {
          default: '#2C2D31',
          paper: '#333333',
        },
        chart:{
          violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
          blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
          green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
          yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
          red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
        },
        
      },
      shape: { borderRadius: 8 },
      typography,
      shadows,
      customShadows,
    }),
    [mode]
  );

  const theme = createTheme(mode === 'light'?themeOptions:themeOptionsDark);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ColorModeContext.Provider value={colorMode}>
        <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
      </ColorModeContext.Provider>
      
    </StyledEngineProvider>
  );
}
