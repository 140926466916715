import { Helmet } from 'react-helmet-async';
import {useLocation} from 'react-router-dom';
// material
import {
  Box,
  Stack,
  Container,
  Typography,
  CircularProgress
} from '@mui/material';
// sections
import  ContactForm  from '../../../sections/@dashboard/siteSection/ContactForm';
// hooks
import useFetch from '../../../hooks/useFetch';
// ----------------------------------------------------------------------




function AddContactPage() {
  const location = useLocation();
  const { data: Contact, loading, error } = useFetch(`/api/contact`,{params:{contactId:location.state.name}});

  return (
    Contact?
    <>
      <Helmet>
        <title> Contact </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {location.state.name? `Modifier ${Contact.email}` :"Ajouter un nouveau contact"}
          </Typography>
        </Stack>
        <ContactForm membre={location.state.name}/>
      </Container>
    </>
    : <Box m={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
  );

}

export default AddContactPage;

