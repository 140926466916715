import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
// material
import {
  Box,
  Stack,
  Container,
  Typography,
  CircularProgress
} from '@mui/material';
// sections
import  POSForm  from '../../sections/@dashboard/pos/POSForm';
// hooks
import useFetch from '../../hooks/useFetch';


// ----------------------------------------------------------------------




function AddPOSPage({route,navigate}) {
  const location = useLocation();
  console.log(location.state.name)
  const { data: Pos, loading, error } = useFetch(`/api/pos`,{params:{posId:location.state.name}});
  // console.log(Team);
  return (
    Pos?
    <>
      <Helmet>
        <title> Magasin </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            
            {location.state.name? `Modifier ${Pos.name}` :"Ajouter un nouveau magasin"}
          </Typography>
        </Stack>
        <POSForm membre={location.state.name?Pos:null}/>
      </Container>
    </>
    :
    <Box m={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
  );
}

export default AddPOSPage;

