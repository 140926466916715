import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
// @mui
import {
  Box,
  Grid,
  Card,
  Badge,
  Stack,
  Alert,
  Button,
  Avatar,
  Snackbar,
  Container,
  Typography,
  TextField,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
// hooks
import useFetch from '../../../hooks/useFetch';
// api
import axios from '../../../api/axios';

export default function ConditionPage() {
  const theme = useTheme();

  const methods = useForm();
  // const onSubmit = data => console.log(data);

  const navigate = useNavigate();
  let USERLIST = [];
  const { data, loading, error } = useFetch('/api/condition');
  if (data) {
    USERLIST = data;
  }
  console.log(USERLIST)

  const arabicRegex = /^[\u0600-\u06FF\s]*$/;

  const [text, setText] = useState(null);
  const [textEnglish, setTextEnglish] = useState(null);
  const [textArabic, setTextArabic] = useState(null);
  const [editText, setEditText] = useState(false);
  const [editTextEnglish, setEditTextEnglish] = useState(false);
  const [editTextArabic, setEditTextArabic] = useState(false);
  const [editName, setEditName] = useState(false);
  const [editPosition, setEditPosition] = useState(false);
  const [hoverText, setHoverText] = useState(false);
  const [hoverTextEnglish, setHoverTextEnglish] = useState(false);
  const [hoverTextArabic, setHoverTextArabic] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [res, setRes] = useState(null);
  const [err, setErr] = useState(null);
  const [open, setOpen] = useState(false);

  const clean = (obj) => {
    Object.keys(obj).forEach((propName) => {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    });
    return obj;
  };

  const onSubmit = (data) => {
    setIsSubmitting(true);

      axios
        .put(
          `api/condition/${USERLIST[0].id}`,
          clean({ text, text_english: textEnglish, text_arabic: textArabic }),
          {
            headers: { 'Content-Type': 'application/json' },
          }
        )
        .then((res) => {
          setRes(res);
          setErr(null);
          window.location.reload(false);
        })
        .catch((error) => {
          console.log(error);
          setErr(error);
          setRes(null);
        })
        .finally(() => {
          setOpen(true);
        });
    

    setIsSubmitting(false);
  };

  const handleEditText = () => {
    setEditText(!editText);
  };
  const handleEditTextEnglish = () => {
    setEditTextEnglish(!editTextEnglish);
  };
  const handleEditTextArabic = () => {
    setEditTextArabic(!editTextArabic);
  };

  const handleMouseEnter = () => {
    setHoverText(true);
  };
  const handleMouseEnterEnglish = () => {
    setHoverTextEnglish(true);
  };
  const handleMouseEnterArabic = () => {
    setHoverTextArabic(true);
  };

  const handleMouseLeave = () => {
    setHoverText(false);
  };
  const handleMouseLeaveEnglish = () => {
    setHoverTextEnglish(false);
  };
  const handleMouseLeaveArabic = () => {
    setHoverTextArabic(false);
  };
  const handleClose = () => {
    setOpen(false);
  };


  return loading ? (
    <Box m={1} display="flex" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  ) : USERLIST ? (
    <>
      <Helmet>
        <title> Mot de directeur </title>
      </Helmet>

      <Container>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {err ? (
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              >
                <Alert variant="filled" onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                  Erreur
                </Alert>
              </Snackbar>
            ) : (
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              >
                <Alert variant="filled" onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                  Modification avec succès.
                </Alert>
              </Snackbar>
            )}
            <Box sx={{ flexGrow: 1, marginBottom: 10 }}>
              <Grid container spacing={2} columns={16}>
                <Grid xs={8}>
                  {editText ? (
                    <>
                      <TextField
                        id="outlined-textarea"
                        fullWidth
                        rows={19}
                        multiline
                        defaultValue={USERLIST[0]?.text}
                        value={text}
                        onChange={(e) => {
                          setText(e.target.value);
                        }}
                      />
                      <Stack component="span" direction="row" alignItems="flex-end" justifyContent="flex-end">
                        <Button variant="text" component="label" color="warning" onClick={() => handleEditText()}>
                          Cancel
                        </Button>
                      </Stack>
                    </>
                  ) : (
                    <Card
                      sx={{
                        padding: '20px',
                        backgroundColor: hoverText ? alpha(theme.palette.grey[500], 0.12) : 'white',
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                      onClick={() => handleEditText()}
                      onMouseEnter={() => handleMouseEnter()}
                      onMouseLeave={() => handleMouseLeave()}
                    >
                      <Typography variant="body1" gutterBottom>
                        {USERLIST[0]?.text}
                      </Typography>
                      <Stack component="span" direction="row" alignItems="flex-end" justifyContent="flex-end">
                        {hoverText ? (
                          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} style={{ color: 'gray' }} />
                        ) : (
                          <></>
                        )}
                      </Stack>
                    </Card>
                  )}
                </Grid>
                <Grid xs={8} spacing={2}>
                  {editTextEnglish ? (
                    <>
                      <TextField
                        id="outlined-textarea"
                        fullWidth
                        rows={19}
                        multiline
                        defaultValue={USERLIST[0]?.text_english}
                        value={textEnglish}
                        onChange={(e) => {
                          setTextEnglish(e.target.value);
                        }}
                      />
                      <Stack component="span" direction="row" alignItems="flex-end" justifyContent="flex-end">
                        <Button
                          variant="text"
                          component="label"
                          color="warning"
                          onClick={() => handleEditTextEnglish()}
                        >
                          Cancel
                        </Button>
                      </Stack>
                    </>
                  ) : (
                    <Card
                      sx={{
                        padding: '20px',
                        backgroundColor: hoverTextEnglish ? alpha(theme.palette.grey[500], 0.12) : 'white',
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                      onClick={() => handleEditTextEnglish()}
                      onMouseEnter={() => handleMouseEnterEnglish()}
                      onMouseLeave={() => handleMouseLeaveEnglish()}
                    >
                      <Typography variant="body1" gutterBottom>
                        {USERLIST[0]?.text_english}
                      </Typography>
                      <Stack component="span" direction="row" alignItems="flex-end" justifyContent="flex-end">
                        {hoverTextEnglish ? (
                          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} style={{ color: 'gray' }} />
                        ) : (
                          <></>
                        )}
                      </Stack>
                    </Card>
                  )}

                  {/* <input type="submit" /> */}
                </Grid>
                <Grid xs={16}>
                  {editTextArabic ? (
                    <>
                      <TextField
                        dir="rtl"
                        id="outlined-textarea"
                        fullWidth
                        rows={19}
                        multiline
                        defaultValue={USERLIST[0]?.text_arabic}
                        value={textArabic}
                        onChange={(e) => {
                          if (!arabicRegex.test(e.target.value)) {
                            // If not, prevent the input
                            e.preventDefault();
                          } else {
                            setTextArabic(e.target.value);
                          }
                        }}
                        inputProps={{
                          pattern: '^[\\u0600-\\u06FF\\s]*$',
                          title: 'Only Arabic letters and spaces are allowed',
                        }}
                      />
                      <Stack component="span" direction="row" alignItems="flex-end" justifyContent="flex-end">
                        <Button variant="text" component="label" color="warning" onClick={() => handleEditTextArabic()}>
                          Cancel
                        </Button>
                      </Stack>
                    </>
                  ) : (
                    <Card
                      sx={{
                        padding: '20px',
                        backgroundColor: hoverTextArabic ? alpha(theme.palette.grey[500], 0.12) : 'white',
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                      onClick={() => handleEditTextArabic()}
                      onMouseEnter={() => handleMouseEnterArabic()}
                      onMouseLeave={() => handleMouseLeaveArabic()}
                    >
                      <Typography dir="rtl" variant="body1" gutterBottom>
                        {USERLIST[0]?.text_arabic}
                      </Typography>
                      <Stack component="span" direction="row" alignItems="flex-end" justifyContent="flex-end">
                        {hoverTextArabic ? (
                          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} style={{ color: 'gray' }} />
                        ) : (
                          <></>
                        )}
                      </Stack>
                    </Card>
                  )}
                </Grid>
              </Grid>
            </Box>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
              Submit
            </LoadingButton>
          </form>
        </FormProvider>
      </Container>
    </>
  ) : (
    <h1>{error}</h1>
  );
}
