import { Helmet } from 'react-helmet-async';
import { filter, set } from 'lodash';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player/lazy';
// @mui
import {
  Box,
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Snackbar,
  Alert,
} from '@mui/material';

// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../../sections/@dashboard/user';
// hooks
import useFetch from '../../../hooks/useFetch';
// api
import axios from '../../../api/axios';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'témoignage', label: 'Témoignage', alignRight: false },
  { id: 'témoignage_english', label: 'Témoignage anglais', alignRight: false },
  { id: 'témoignage_arabic', label: 'Témoignage arabe', alignRight: false },
  { id: 'name', label: 'Nom client', alignRight: false },
  { id: 'activity', label: 'Activité client', alignRight: false },
  { id: 'name_arabic', label: 'Nom client arabe', alignRight: false },
  { id: 'activity_arabic', label: 'Activité client arabe', alignRight: false },
  { id: 'name_english', label: 'Nom client anglais', alignRight: false },
  { id: 'activity_english', label: 'Activité client anglais', alignRight: false },
  { id: 'image', label: 'Photo client', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ClientQuotePage() {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/temoignage/edit/${deletedItemId}`, { state: { name: deletedItemId } });
  };
  let USERLIST = [];
  const { data, loading, error } = useFetch('/api/clientQuote');
  if (data) {
    USERLIST = data;
    console.log(USERLIST);
  }
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [openSnack, setOpenSnack] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const [openDialogImage, setOpenDialogImage] = useState(false);

  const [deletedItem, setDeletedItem] = useState(null);

  const [deletedItemId, setDeletedItemId] = useState(null);

  const [image1, setImage] = useState(null);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenDialogImage = (event, quote) => {
    setImage(quote);
    setOpenDialogImage(true);
  };

  const handleCloseDialogImage = () => {
    setOpenDialogImage(false);
  };

  const handleOpenMenu = (event, email, id) => {
    setDeletedItem(email);
    setDeletedItemId(id);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const handleClickDelete = () => {
    console.log(deletedItemId);
    axios
      .delete(`api/clientQuote/${deletedItemId}`)
      .then()
      .catch((error) => {})
      .finally(() => {
        setOpenSnack(true);
        if (!error) {
          window.location.reload(false);
        }
      });

    handleCloseDialog();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return loading ? (
    <Box m={1} display="flex" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  ) : USERLIST ? (
    <>
      <Helmet>
        <title> Témoignage </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Témoignage
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/temoignage/add"
            state={{ from: 'occupation' }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Nouveau Temoignage
          </Button>
        </Stack>

        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            pageName="témoignage"
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />

                <TableBody>
                  {USERLIST.length <= 0 ? (
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Il n'y a pas du témoignage
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const {
                        id,
                        quote,
                        is_text: text,
                        is_video: video,
                        is_image: image,
                        name,
                        activity,
                        name_arabic:nameArab,
                        activity_arabic:activityArab,
                        name_english:nameEnglish,
                        activity_english:activityEnglish,
                        quote_english: quoteEnglish,
                        quote_arabic: quoteArabic,
                        image_client: imageClient,
                      } = row;
                      const selectedUser = selected.indexOf(quote) !== -1;

                      return (
                        <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                          <TableCell padding="checkbox">
                            <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, quote)} />
                          </TableCell>

                          <TableCell component="th" scope="row" padding="none">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              {/* <Avatar alt={name} src={avatarUrl} /> */}
                              <Typography variant="subtitle2" noWrap>
                                {text === 1 ? (
                                  quote
                                ) : (
                                  <Button onClick={(event) => handleOpenDialogImage(event, quote)}>
                                    Voir témoignage
                                  </Button>
                                )}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell align="left">{text === 1 ? quoteEnglish : '/'}</TableCell>
                          <TableCell align="left">{text === 1 ? quoteArabic : '/'}</TableCell>
                          <TableCell align="left">{name}</TableCell>
                          <TableCell align="left">{activity}</TableCell>
                          <TableCell align="left">{nameArab}</TableCell>
                          <TableCell align="left">{activityArab}</TableCell>
                          <TableCell align="left">{nameEnglish}</TableCell>
                          <TableCell align="left">{activityEnglish}</TableCell>
                          <TableCell align="left">
                            <Box>
                              <img src={`${process.env.REACT_APP_PUBLIC_URL}${imageClient}`} alt={`pic-client`} />
                            </Box>
                          </TableCell>

                          <TableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              onClick={(event) => handleOpenMenu(event, name, id)}
                            >
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </TableCell>
                          <Popover
                            open={Boolean(open)}
                            anchorEl={open}
                            onClose={handleCloseMenu}
                            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                            PaperProps={{
                              sx: {
                                p: 1,
                                width: 140,
                                '& .MuiMenuItem-root': {
                                  px: 1,
                                  typography: 'body2',
                                  borderRadius: 0.75,
                                },
                              },
                            }}
                          >
                            <MenuItem onClick={(event) => handleNavigate(event)}>
                              <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                              Edit
                            </MenuItem>
                            <MenuItem sx={{ color: 'error.main' }} onClick={(event) => handleOpenDialog(event)}>
                              <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                              Delete
                            </MenuItem>
                          </Popover>
                          <Dialog
                            open={openDialog}
                            onClose={handleCloseDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            <DialogTitle id="alert-dialog-title">
                              Supprimer le témoignage de {deletedItem?.first_name}
                            </DialogTitle>
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                Veuillez vraiment supprimer ce témoignage
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handleCloseDialog}>Annuler</Button>
                              <Button onClick={handleClickDelete} autoFocus>
                                Accepter
                              </Button>
                            </DialogActions>
                          </Dialog>

                          <Dialog
                            open={openDialogImage}
                            onClose={handleCloseDialogImage}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                          >
                            {/* <DialogTitle id="alert-dialog-title"></DialogTitle> */}
                            <DialogContent>
                              <DialogContentText id="alert-dialog-description">
                                {
                                  video === 1?
                                  <ReactPlayer url={`${process.env.REACT_APP_PUBLIC_URL}${image1}`} />
                                  :
                                  <img src={`${process.env.REACT_APP_PUBLIC_URL}${image1}`} alt=""/>
                                }
                                
                                {/* {`http://127.0.0.1:8000/${image1}`} */}
                                {/* <video controls autoPlay> 
                                 <track src="captions_en.vtt" kind="captions" srcLang="en" label="english_captions"/> 
                                   <source src={`http://127.0.0.1:8000/${image1}`} type="mp4" />
                                </video>   */}

                                {/* <video controls>
        <source src={`http://127.0.0.1:8000/img/clientQuote/production_id_4267245(2160p).mp4`} type="video/mp4" />
        
        {/* Add additional source elements for different video formats (webm, ogg, etc.) */}

                                {/* <img src={`https://lab.saroussa.com/${image1}`} alt={`client`} /> */}
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <Button onClick={handleCloseDialogImage}>Annuler</Button>
                            </DialogActions>
                          </Dialog>

                          {error ? (
                            <Snackbar
                              open={openSnack}
                              autoHideDuration={6000}
                              onClose={handleCloseSnack}
                              anchorOrigin={{ vertical: 'buttom', horizontal: 'right' }}
                            >
                              <Alert
                                variant="filled"
                                onClose={handleCloseSnack}
                                severity="error"
                                sx={{ width: '100%' }}
                              >
                                Erreur dans la suppression du témoignage
                              </Alert>
                            </Snackbar>
                          ) : (
                            <Snackbar
                              open={openSnack}
                              autoHideDuration={6000}
                              onClose={handleCloseSnack}
                              anchorOrigin={{ vertical: 'buttom', horizontal: 'right' }}
                            >
                              <Alert
                                variant="filled"
                                onClose={handleCloseSnack}
                                severity="success"
                                sx={{ width: '100%' }}
                              >
                                Le témoignage a été suprimer avec succès.
                              </Alert>
                            </Snackbar>
                          )}
                        </TableRow>
                      );
                    })
                  )}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Introuvable
                          </Typography>

                          <Typography variant="body2">
                            Aucun résultat trouvé pour &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Essayez de vérifier les fautes de frappe ou d'utiliser des mots complets.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  ) : (
    <h1>{error}</h1>
  );
}
