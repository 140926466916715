import * as Yup from 'yup';
import { useState } from 'react';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFormContext, Controller } from 'react-hook-form';
// @mui
import { InputLabel,
     Button, 
     Stack, 
     Snackbar, 
     Alert, 
     IconButton, 
     InputAdornment, 
     Box, 
     CircularProgress, 
     Typography, 
     Autocomplete, 
     TextField, 
     FormControlLabel,
     FormLabel,
     Radio,
     RadioGroup

} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
// api
import axios from '../../../api/axios';
// components
import Iconify from '../../../components/iconify';
import { FormProvider, RHFTextField, RHFFile, RHFSelect } from '../../../components/hook-form';
// hooks
import useFetch from '../../../hooks/useFetch';
// import Button from 'src/theme/overrides/Button';

// ----------------------------------------------------------------------

function getStyles(name, role, theme) {
    return {
        fontWeight:
            role.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function ClientForm({ membre }) {
    const { data: Wilayas, loadingWilayas, errorWilayas } = useFetch('/api/wilayas');
    const theme = useTheme();
    const categoryList = [
        'Grossiste', 'Demi gros', 'Detaillant'
    ]

    const [showPassword, setShowPassword] = useState(false);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);
    const [generatedPasword, setGeneratedPassword] = useState(null);
    const [role, setRole] = useState([]);
    const [wil, setWil] = useState("");
    const [com, setCom] = useState("");
    const [wil2, setWil2] = useState("");
    const [com2, setCom2] = useState('');
    const [communes, setcommunes] = useState([]);
    const [value, setValue] = useState('Grossiste');

    // const LoginSchema = Yup.object().shape({
    //   name: Yup.string().required('Nom obligatoire'),
    //   image_url: Yup.mixed()
    // });
    const phoneRegExp = '(0||\\+213)[1-9][0-9]{8}';

    const LoginSchema = Yup.object().shape({
        first_name: membre ? Yup.string() : Yup.string().required('Nom obligatoire'),
        last_name: membre ? Yup.string() : Yup.string().required('Prénom obligatoire'),
        email: membre ? Yup.string().email() : Yup.string().email("E-mail doit etre valide").required('E-mail obligatoire'),
        password: membre ? Yup.string() : Yup.string().required('Mot de passe obligatoire'),
        passwordConfirmation: membre ? Yup.string() : Yup.string().oneOf([Yup.ref('password'), null], 'Les mots de passe doivent correspondre'),
        phone: membre ? Yup.string() : Yup.string().required('Numéro de téléphone obligatoire').matches(phoneRegExp, "Numéro de téléphone n'est pas valide"),
        a_imposition: membre ? Yup.string() : Yup.string().required('Imposition obligatoire'),
        nis: membre ? Yup.number().typeError('NIS valide') : Yup.number().typeError('NIS doit etre valide').positive('NIS doit etre valide'),
        nif: membre ? Yup.number().typeError('NIF valide') : Yup.number().typeError('NIF doit etre valide').positive('NIF doit etre valide'),
        adress: membre ? Yup.string() : Yup.string().required('Adresse obligatoire'),
        registre:membre? Yup.mixed().notRequired():Yup.mixed().required('Image obligatoire').test('fileExist',"Image obligatoire",(value)=>{
            return  value && value?.name
          })
          .test('type',"Image extension invalid",(value)=>{
           
            return value && (value.type==="image/png" || value.type==="image/jpeg")
          })
          .test('fileSize',"La taille de fichier est trop volumineux",(value)=>{ 
            return value && value.size <= 2000000
          }),
        // category: membre ? Yup.string() : Yup.string().required('Catégorie obligatoire'),

        // roles: membre ? Yup.array() : Yup.array().required('Role obligatoire').min(1, "Role obligatoire."),

    });

    const clean = (obj) => {
        Object.keys(obj).forEach((propName) => {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
                delete obj[propName];
            }
        })
        return obj
    }

    const generatePassword = () => {

        const randomPassword =
            Math.random().toString(36).slice(8) + Math.random().toString(36).slice(8);
        setGeneratedPassword(randomPassword);
        console.log(generatedPasword);
    }

    const defaultValues = {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        phone: '',
        a_imposition: '',
        nis: '',
        nif: '',
        adress: '',
        registre: null,
        // category: ''

    };

    const onChangeWil = (event, value) => {
        console.log(value.id);
        setWil(value.name);
        setWil2(value.id);
        axios
          .get(`/api/wilayas/${value.id}/communes`)
          .then((response) => {
            // setData(response.data);
            setcommunes(response.data);
          })
          .catch((error) => {
            // setError(error);
            console.log(error);
          })
          .finally(() => {
            // setLoading(false);
          });
        // setcommunes(Communes);
      }

      const onChangeCom = (event,value) => {
        console.log(value.id);
        setCom(value.name);
        setCom2(value.id);
      }

    const handleClose = () => {
        setOpen(false);
    };

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;

    const handleChange = (event) => {
        setValue(event.target.value);
      };

    const handleChangeRole = (event) => {
        setRole(typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value);
        console.log(role);

    }
    const getRole = (role) => {
        const roles = []
        role.forEach((item) => (
            item === 'Admin' ? roles.push('ROLE_ADMIN')
                : item === 'User' ? roles.push('ROLE_USER')
                    : roles.push('ROLE_CLIENT')
        )

        );
        return roles

    }
    const onSubmit = async (values) => {
        console.log('hell');
        values.category = value;
        values.wilayas_id = wil2;
        values.communes_id = com2;
        
        if (membre) {





            if(values.registre!=null){
                axios
                  .post('api/registreFile', {registre:values.registre}, { mode: 'no-cors' })
                  .then((res) => {
                    setResponse(res);
                    setError(null);
                    values.registre = res.data;
                    // window.location.reload(false);
                  })
                  .catch((error) => {
                    
                    setError(error);
                    setResponse(null);
                  })
                  .finally(() => {
                    // setOpen(true);
            
            
                    axios
                    .put(`api/user/${membre}`, clean(values), { headers: { "Content-Type": "application/x-www-form-urlencoded" } })
                    .then((res) => {
                        setResponse(res);
                        setError(null);
                        window.location.reload(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        setError(error);
                        setResponse(null);
                    })
                    .finally(() => {
                        setOpen(true);
                    });
                  });
            }else{
                axios
                .put(`api/user/${membre}`, clean(values), { headers: { "Content-Type": "application/x-www-form-urlencoded" } })
                .then((res) => {
                    setResponse(res);
                    setError(null);
                    window.location.reload(false);
                })
                .catch((error) => {
                    console.log(error);
                    setError(error);
                    setResponse(null);
                })
                .finally(() => {
                    setOpen(true);
                });
            
            }

        } else {
            axios
                .post('api/user', { ...values, roles: ['ROLE_CLIENT'] }, { mode: 'no-cors' })
                .then((res) => {
                    setResponse(res);
                    setError(null);
                    window.location.reload(false);
                })
                .catch((error) => {
                    console.log(error);
                    setError(error);
                    setResponse(null);
                })
                .finally(() => {
                    setOpen(true);
                });
        }
    }

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {error ? (
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert variant="filled" onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        {membre ? "Erreur dans la modification de l'utilisateur" : "Utilisateur existe déja."}
                    </Alert>
                </Snackbar>
            ) : (
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert variant="filled" onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        L'utilisateur a été {membre ? "modifié" : "ajouté"}  avec succès.
                    </Alert>
                </Snackbar>
            )}
            <Stack spacing={3}>

            <Box  sx={{ display: 'flex', direction:'row', justifyContent: 'space-between' }}>
                <RHFTextField name="first_name" label="Nom" sx={{width:'49%'}} />
                <RHFTextField name="last_name" label="Prénom" sx={{width:'49%'}}/>
            </Box>
                
                <RHFTextField name="email" label="E-mail" />
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={{
                        xs: 0.5,
                        sm: 1,
                    }}
                >
                    <RHFTextField
                        name="password"
                        label="Mot de passe"
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {/* <Button
                        onClick={() => generatePassword()}
                    >Générer</Button> */}
                </Stack>
                <RHFTextField
                    name="passwordConfirmation"
                    label="Confirmer le mot de passe"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <RHFTextField name="phone" label="N° téléphone" />
                <Box  sx={{ display: 'flex', direction:'row', justifyContent: 'space-between' }}>
                    <RHFTextField name="a_imposition" label="A.Imposition" sx={{width:'32%'}}/>
                    <RHFTextField name="nis" label="NIS" sx={{width:'32%'}}/>
                    <RHFTextField name="nif" label="NIF" sx={{width:'32%'}}/>
                </Box>
                <Box  sx={{ display: 'flex', direction:'row',justifyContent: 'space-between' }}>
                {loadingWilayas && (
                    <Box m={1} display="flex" justifyContent="center" alignItems="center">
                        <CircularProgress />
                    </Box>
                )}
                
                {Wilayas && <Autocomplete
                    sx={{width:'49%'}}
                    options={Wilayas}
                    getOptionLabel={(option) => option.name ? option.name : wil}
                    getOptionSelected={(option, value) => option.name === value.name}

                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    id="controlled-demo"
                    value={wil}
                    onChange={onChangeWil}
                    renderInput={(params) => (

                        <TextField {...params} label="Wilayas" fullWidth value={wil} />
                    )}
                />}
                {errorWilayas && (
                    <Typography variant="overline" display="block" gutterBottom color={'red'}>
                        erreur dans le chargement des wilayas
                    </Typography>
                )}

                <Autocomplete
                    sx={{width:'49%'}}
                    options={communes}
                    getOptionLabel={(option) => option.name ? option.name : com}
                    getOptionSelected={(option, value) => option.name === value.name}

                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    id="controlled-demo"
                    value={com}
                    onChange={onChangeCom}
                    renderInput={(params) => (

                        <TextField {...params} label="Commune" fullWidth />
                    )}
                    
                />
                </Box>
                {/* <RHFTextField name="roles" label="Role" /> */}
                {/* <RHFSelect options={[...categoryList]} name="category" label="Catégorie" /> */}
                <RHFTextField name="adress" label="Adresse" />
                <FormLabel id="demo-row-radio-buttons-group-label">Catégorie</FormLabel>
                <RadioGroup
                    row
                    
                    defaultValue="Grossiste"
                    value={value}
                    onChange={handleChange}
                >
                    <FormControlLabel value="Grossiste" control={<Radio />} label="Grossiste" />
                    <FormControlLabel value="Demi_gros" control={<Radio />} label="Demi gros" />
                    <FormControlLabel value="Detaillant" control={<Radio />} label="Detaillant" />
                </RadioGroup>
                <RHFFile name="registre" label="Registre de commerce" />

                <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                    Submit
                </LoadingButton>
            </Stack>
        </FormProvider>
    );
}
