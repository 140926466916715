import axios from 'axios';

export default axios.create({
  // baseURL: process.env.REACT_APP_PUBLIC_URL,
  baseURL: 'https://lab.saroussa.com',
  withCredentials: true,

  headers: {
    // 'Access-Control-Allow-Origin': '*',

    'Access-Control-Allow-Origin': 'https://panel.saroussa.com/*',
    'Access-Control-Allow-Credentials': 'true',
    'Access-Control-Allow-Headers': 'content-type',
    'Access-Control-Allow-Methods': 'PUT, POST, GET, DELETE, PATCH, OPTIONS',
    'Content-Type': 'multipart/form-data',
    
  },
});


// const csrfToken = document.head.querySelector('meta[name="csrf-token"]').content;

// // Include CSRF token in Axios headers
// axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
// axios.defaults.headers.common.Authorization = `Bearer ${Cookies.get('saroussa_session')}`
