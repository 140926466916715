import * as Yup from 'yup';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Snackbar, Alert, Box, CircularProgress, Typography, Autocomplete, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// api
import axios from '../../../api/axios';
// components
import { FormProvider, RHFTextField, RHFFile, RHFSelect } from '../../../components/hook-form';
// hooks
import useFetch from '../../../hooks/useFetch';

// ----------------------------------------------------------------------
export default function HistoriqueForm({ membre }) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  const LoginSchema = Yup.object().shape({
    year: membre
      ? Yup.number()
          .nullable()
          .typeError("Année n'est pas valide")
          .min(2002, "Année n'est pas valide")
          .max(new Date().getFullYear(), "Année n'est pas valide")
          .transform((_, val) => (val !== '' ? Number(val) : null))
      : Yup.number()
          .typeError("Année n'est pas valide")
          .min(2002, "Année n'est pas valide")
          .max(new Date().getFullYear(), "Année n'est pas valide")
          .required('Année obligatoire'),
    event: membre ? Yup.string() : Yup.string().required('événement obligatoire'),
    title: membre ? Yup.string() : Yup.string().required('titre obligatoire'),
    event_english: membre ? Yup.string() : Yup.string().required('événement obligatoire'),
    title_english: membre ? Yup.string() : Yup.string().required('titre obligatoire'),
    event_arabic: membre ? Yup.string() : Yup.string().required('événement obligatoire'),
    title_arabic: membre ? Yup.string() : Yup.string().required('titre obligatoire'),
    image:membre? Yup.mixed().notRequired(): Yup.mixed().test('fileExist',"Image obligatoire",(value)=>{
      return  value && value?.name
    })
    .test('type',"Image extension invalid",(value)=>{
     
      return value && (value.type==="image/png" || value.type==="image/jpeg" || value.type === "image/svg+xml")
    })
    .test('fileSize',"La taille de fichier est trop volumineux",(value)=>{ 
      return value && value.size <= 2000000
    }),
  });

  const clean = (obj) => {
    Object.keys(obj).forEach((propName) => {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    });
    return obj;
  };
  const defaultValues = {
    year: '',
    event: '',
    title: '',
    event_english: '',
    title_english: '',
    event_arabic: '',
    title_arabic: '',
    image: null,
  };

  const handleClose = () => {
    setOpen(false);
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (values) => {
    if (membre) {
      if (values.image != null) {
        axios
          .post('api/historiqueFile', { image : values.image }, { mode: 'no-cors' })
          .then((res) => {
            setResponse(res);
            setError(null);
            // setImage(res.data);
            // console.log(res.data);
            // window.location.reload(false);
          })
          .catch((error) => {
            setError(error);
            setResponse(null);
          })
          .finally(() => {
           
           
            values.image = `img/historique/${values.image.name}`;
  
             const val = clean(values);
              axios
                .put(`api/historique/${membre}`, val, { headers: { 'Content-Type': 'application/json' } })
                .then((res) => {
                  setResponse(res);
                  setError(null);
                  window.location.reload(false);
                })
                .catch((error) => {
                  console.log(error);
                  setError(error);
                  setResponse(null);
                })
                .finally(() => {
                  setOpen(true);
                });
            
          });
      } else {
        delete values.image
        const val = clean(values);
        axios
          .put(`api/historique/${membre}`, val, {
            headers: { 'Content-Type': 'application/json' },
          })
          .then((res) => {
            setResponse(res);
            setError(null);
            window.location.reload(false);
          })
          .catch((error) => {
            console.log(error)
            setError(error);
            setResponse(null);
          })
          .finally(() => {
            setOpen(true);
          });
      }
    } else {
      axios
        .post('api/historique', values, { mode: 'no-cors' })
        .then((res) => {
          setResponse(res);
          setError(null);
          window.location.reload(false);
        })
        .catch((error) => {
          console.log(error);
          setError(error);
          setResponse(null);
        })
        .finally(() => {
          setOpen(true);
        });
    }
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {error ? (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert variant="filled" onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Événement existe déja.
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert variant="filled" onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            L'événement' a été ajouté avec succès.
          </Alert>
        </Snackbar>
      )}
      <Stack spacing={3}>
        <Stack component="span" direction="row">
          <RHFTextField name="year" label="Année" sx={{ width: '30%' }} />
          <RHFTextField name="title" label="Événement" />
        </Stack>
        <Stack component="span" direction="row">
          <RHFTextField name="title_english" label="Événement anglais"  />
          <RHFTextField dir="rtl" name="title_arabic" label="الحدث" />
        </Stack>

        <RHFTextField name="event" label="Description" rows={3} multiline />
        <RHFTextField name="event_english" label="Description anglais" rows={3} multiline />
        <RHFTextField dir="rtl" name="event_arabic" label="Description arab" rows={3} multiline />
        <RHFFile name="image" label="Image" />
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Submit
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
