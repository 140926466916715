import { useState } from 'react';
// @mui
import { Menu, Button, MenuItem, Typography } from '@mui/material';
// component
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const SORT_BY_OPTIONS = [
  { value: 'Tous', label: 'Tous' },
  { value: 'Grossiste', label: 'Grossiste' },
  { value: 'Demi gros', label: 'Demi gros' },
  { value: 'Detaille', label: 'Détaillant' },
];

export default function UserSort(props) {
  const [open, setOpen] = useState(null);
  const [sortBy, setSortBy] = useState('Tous');
  const [sortByValue, setSortByValue] = useState('tous');

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMenuItemClick = (event) => {
    setSortByValue(event.target.getAttribute('data-value'));
    setSortBy(event.target.innerText);
    props.onSortByChange(event.target.getAttribute('data-value'));
    handleClose();
  };


  return (
    <>
      <Button
        color="inherit"
        disableRipple
        onClick={handleOpen}
        endIcon={<Iconify icon={open ? 'eva:chevron-up-fill' : 'eva:chevron-down-fill'} />}
      >
        Filtrer par catégorie:&nbsp;
        <Typography component="span" variant="subtitle2" sx={{ color: 'text.secondary' }}>
          {sortBy}
        </Typography>
      </Button>
      <Menu
        keepMounted
        anchorEl={open}
        open={Boolean(open)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {SORT_BY_OPTIONS.map((option) => (
          <MenuItem
            key={option.value}
            data-value={option.value}
            onClick={handleMenuItemClick}
            sx={{ typography: 'body2' }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
