import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
import account from '../../../_mock/account';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';
// context
import useAuthContext from '../../../context/AuthContext'

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  const { user } = useAuthContext();

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar src={account.photoURL} alt="avatar" />

            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {user?.first_name}
              </Typography>

              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {user?.last_name}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}

// import PropTypes from 'prop-types';
// import { useEffect, useContext } from 'react';
// import { Link as RouterLink, useLocation } from 'react-router-dom';
// // material
// import { styled } from '@mui/material/styles';
// import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// // mock
// import account from '../../../_mock/account';
// // hooks
// import useResponsive from '../../../hooks/useResponsive';
// // components
// import Logo from '../../../components/logo';
// import Scrollbar from '../../../components/scrollbar';
// import NavSection from '../../../components/nav-section';
// //
// import navConfig from './config';
// import useAuthContext from '../../../context/AuthContext';

// // ----------------------------------------------------------------------

// const DRAWER_WIDTH = 280;

// const RootStyle = styled('div')(({ theme }) => ({
//   [theme.breakpoints.up('lg')]: {
//     flexShrink: 0,
//     width: DRAWER_WIDTH,
//   },
// }));

// const AccountStyle = styled('div')(({ theme }) => ({
//   display: 'flex',
//   alignItems: 'center',
//   padding: theme.spacing(2, 2.5),
//   borderRadius: Number(theme.shape.borderRadius) * 1.5,
//   backgroundColor: theme.palette.grey[500_12],
// }));

// // ----------------------------------------------------------------------

// Nav.propTypes = {
//   isOpenSidebar: PropTypes.bool,
//   onCloseSidebar: PropTypes.func,
// };

// export default function Nav({ isOpenSidebar, onCloseSidebar }) {
//   const { pathname } = useLocation();
//   const { user, auth } = useAuthContext();
//   const isDesktop = useResponsive('up', 'lg');

//   useEffect(() => {
//     if (isOpenSidebar) {
//       onCloseSidebar();
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [pathname]);

//   const renderContent = (
//     <Scrollbar
//       sx={{
//         height: 1,
//         '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
//       }}
//     >
//       <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
//         <Logo />
//       </Box>

//       <Box sx={{ mb: 3, mx: 1.5 }}>
//         <Link underline="none" component={RouterLink} to="/">
//           <AccountStyle>
//             <Avatar 
//             // src={JSON.parse(localStorage.getItem("user")).image_url?JSON.parse(localStorage.getItem("user")).image_url:`${process.env.PUBLIC_URL}/static/illustrations/illustration_avatar.png`} 
//             alt="Avatar" 
//             sx={{ height: 50, width:50 }}
//             />
//             <Box sx={{ ml: 1 }}>
//               <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
//                  {/* {JSON.parse(localStorage.getItem("user")).first_name}  {JSON.parse(localStorage.getItem("user")).last_name} */}
//               </Typography>
//               <Typography variant="body2" sx={{ color: 'text.secondary' }}>
//                 {/* {account.role} */}
//               </Typography>
//             </Box>
//           </AccountStyle>
//         </Link>
//       </Box>

//       <NavSection navConfig={navConfig} />

//       <Box sx={{ flexGrow: 1 }} />
//     </Scrollbar>
//   );

//   return (
//     <RootStyle>
//       {!isDesktop && (
//         <Drawer
//           open={isOpenSidebar}
//           onClose={onCloseSidebar}
//           PaperProps={{
//             sx: { width: DRAWER_WIDTH },
//           }}
//         >
//           {renderContent}
//         </Drawer>
//       )}

//       {isDesktop && (
//         <Drawer
//           open
//           variant="persistent"
//           PaperProps={{
//             sx: {
//               width: DRAWER_WIDTH,
//               bgcolor: 'background.default',
//               borderRightStyle: 'dashed',
//             },
//           }}
//         >
//           {renderContent}
//         </Drawer>
//       )}
//     </RootStyle>
//   );
// }
