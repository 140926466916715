import * as Yup from 'yup';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Snackbar, Alert, Box, CircularProgress, Typography, Autocomplete, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// api
import axios from '../../../api/axios';
// components
import { FormProvider, RHFTextField, RHFFile, RHFSelect, RHFCheckbox } from '../../../components/hook-form';
// hooks
import useFetch from '../../../hooks/useFetch';

// ----------------------------------------------------------------------

export default function PromotionForm({ membre }) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  console.log(membre);

  const LoginSchema = Yup.object().shape({
    url: Yup.string(),
    banner: Yup.bool(),
    promotion: Yup.bool(),
    image: membre
      ? Yup.mixed().nullable()
      : Yup.mixed()
          .test('fileExist', 'Image obligatoire', (value) => {
            return value && value?.name;
          })
          .test('type', 'Image extension invalid', (value) => {
            return (
              value && (value.type === 'image/png' || value.type === 'image/jpeg' || value.type === 'image/svg+xml')
            );
          })
          .test('fileSize', 'La taille de fichier est trop volumineux', (value) => {
            return value && value.size <= 20000000;
          }),
  });

  const clean = (obj) => {
    Object.keys(obj).forEach((propName) => {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    });
    return obj;
  };
  const defaultValues = {
    url: '',
    banner: membre ? membre.banner : 0,
    promotion: membre ? membre.promotion : 0,
    image: null,
  };

  const handleClose = () => {
    setOpen(false);
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (values) => {
    if (values.banner === true) {
      values.banner = 1;
    } else {
      values.banner = 0;
    }

    if (values.promotion === true) {
      values.promotion = 1;
    } else {
      values.promotion = 0;
    }















    if(membre){
      
      if (values.image != null) {
        axios
          .post('api/promotionFile', { image : values.image }, { mode: 'no-cors' })
          .then((res) => {
            setResponse(res);
            setError(null);
            // setImage(res.data);
            // console.log(res.data);
            // window.location.reload(false);
          })
          .catch((error) => {
            setError(error);
            setResponse(null);
          })
          .finally(() => {
           
           
            values.image = `img/promotion/${values.image.name}`;
  
             const val = clean(values);
              axios
                .put(`api/promotion/${membre.id}`, val, { headers: { 'Content-Type': 'application/json' } })
                .then((res) => {
                  setResponse(res);
                  setError(null);
                  window.location.reload(false);
                })
                .catch((error) => {
                  console.log(error);
                  setError(error);
                  setResponse(null);
                })
                .finally(() => {
                  setOpen(true);
                });
            
          });
      } else {
        delete values.image
        const val = clean(values);
        axios
          .put(`api/promotion/${membre.id}`, val, {
            headers: { 'Content-Type': 'application/json' },
          })
          .then((res) => {
            setResponse(res);
            setError(null);
            window.location.reload(false);
          })
          .catch((error) => {
            console.log(error)
            setError(error);
            setResponse(null);
          })
          .finally(() => {
            setOpen(true);
          });
      }
    }else{
      console.log('here 3')
        axios
          .post('api/promotion', values, { mode: 'no-cors' })
          .then((res) => {
            setResponse(res);
            setError(null);
            window.location.reload(false);
          })
          .catch((error) => {
            console.log(error);
            setError(error);
            setResponse(null);
          })
          .finally(() => {
            setOpen(true);
          });
        }
























    // if (membre) {
    //   axios
    //     .post(`api/promotion`, clean(values), { mode: 'no-cors' })
    //     .then((res) => {
    //       setResponse(res);
    //       setError(null);
    //       window.location.reload(false);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       setError(error);
    //       setResponse(null);
    //     })
    //     .finally(() => {
    //       setOpen(true);
    //     });
    // } else {
    //   axios
    //     .post(`api/promotion`, values, { mode: 'no-cors' })
    //     .then((res) => {
    //       setResponse(res);
    //       setError(null);
    //       window.location.reload(false);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       setError(error);
    //       setResponse(null);
    //     })
    //     .finally(() => {
    //       setOpen(true);
    //     });
    // }
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {error ? (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert variant="filled" onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Promotion existe déja.
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert variant="filled" onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            La promotion a été ajouté avec succès.
          </Alert>
        </Snackbar>
      )}
      <Stack spacing={3}>
        <RHFTextField name="url" label="Url" />
        <RHFCheckbox name="banner" label="Ajouter dans le banner" />
        <RHFCheckbox name="promotion" label="Ajouter dans les promotions" />
        <RHFFile name="image" label="Image" />
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Submit
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
