import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// @mui
import { MenuItem, TextField, CircularProgress, Box, Typography, Paper, Grid, Stack, Button } from '@mui/material';
import useFetch from '../../../hooks/useFetch';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

// BlogPostsSort.propTypes = {
//   options: PropTypes.array,
//   onSort: PropTypes.func,
// };

export default function BlogViewPost({ post }) {
  const location = useLocation();
  const navigate = useNavigate();
  console.log(location.state.name);
  const { data: Blog, loading, error } = useFetch(`/api/news`, { params: { newsId: location.state.name } });
  const handleNavigateModifier = () => {
    navigate(`/blog/edit/${location.state.name}`, { state: { name: location.state.name } });
  };

  return loading ? (
    <Box m={1} display="flex" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  ) : Blog ? (
    <Grid item key={Blog.id} xs={12} sm={6} md={4} lg={3}>
        <Stack direction="row" alignItems="end" justifyContent="end" mb={5}>
          
          <Button variant="contained" startIcon={<Iconify icon="eva:edit-fill" />} onClick={()=>{handleNavigateModifier()}}>
            Modifier
          </Button>
        </Stack>
      <Paper elevation={3} style={{ padding: '16px' }}>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="70vh">
          <img src={process.env.REACT_APP_PUBLIC_URL + Blog.image} alt={Blog.title} style={{ height: '65vh' }} />
        </Box>

        <Typography variant="body1" gutterBottom>
          {Date(Blog.created_at).substring(4, 15)}
        </Typography>
        <Typography variant="h4" gutterBottom>
          {Blog.title}
        </Typography>
        <Typography variant="body1">{Blog.text}</Typography>
        <hr/>
        <Typography variant="h4" gutterBottom>
          {Blog.title_english}
        </Typography>
        <Typography variant="body1">{Blog.text_english}</Typography>
        <hr/>
        <Typography variant="h4" gutterBottom>
          {Blog.title_arabic}
        </Typography>
        <Typography variant="body1">{Blog.text_arabic}</Typography>
      </Paper>
    </Grid>
  ) : (
    <div>ERROR...</div>
  );
}
