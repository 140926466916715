import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as FileSaver from 'file-saver';
import * as XLSX from "xlsx";
// @mui
import {
  Box,
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Snackbar,
  Alert,
  Collapse,
  TableHead
} from '@mui/material';
// components
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
// hooks
import useFetch from '../../hooks/useFetch';
// api
import axios from '../../api/axios';


// ----------------------------------------------------------------------

const TABLE_HEAD = [



  { id: 'nom', label: 'Nom', alignRight: false },
  { id: 'prenom', label: 'Prénom', alignRight: false },
  { id: 'email', label: 'E-mail', alignRight: false },
  { id: 'message', label: 'Message', alignRight: false },
  { id: '', label: '', alignRight: false },
 
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function MessagePage() {
  const navigate = useNavigate();
  let BRANDLIST = [];
  
  const { data, loading, error } = useFetch('/api/message');
  

  
  
  if (data) {
    
    BRANDLIST = data;
  }
  const [open, setOpen] = useState(null);

  const [openModal, setOpenModal] = useState(-1);

  const [isFromModal, setIsFromModal] = useState(false);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [openSnack, setOpenSnack] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const [deletedItem, setDeletedItem] = useState(null);

  const [deletedItemId, setDeletedItemId] = useState(null);

  const [deletedModalItemId, setDeletedModalItemId] = useState(null);

  const [openExtend, setOpenExtend] = useState(false);
  const [image, setImage] = useState(null);
  const [errorImage, setErrorImage] = useState(null);
  const [loadingImage, setLoadingImage] = useState(null);

 
  const filename = `Commande`;

  const handleNavigate = () => {
    navigate(`/brand/edit/${deletedItemId}`, { state: { name: deletedItemId } });
  };
  const handleNavigateModal = () => {
    navigate(`/modal/edit/${deletedItemId}`, { state: { name: deletedItemId } });
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleOpenMenu = (event, name, id, isModal) => {
    if (isModal === true) {
      setIsFromModal(true);
    }
    setDeletedItem(name);
    setDeletedItemId(id);
    setOpen(event.currentTarget);
  };

  const handleExtend = (event, name, imageUrl) => {
    setOpenExtend(true);
    setImage(imageUrl);
    setDeletedItem(name);
    // axios
    //     .get(`/api/image/brand/warren-wong-bh4LQHcOcxE-unsplash.jpg`)
    //     .then(async(response)  => {
    //       console.log(response.data);
    // //       const res = response.data;
    // //       const imageBlob = await res.blob();
    // // const imageObjectURL = URL.createObjectURL(imageBlob);
    // // console.log(imageObjectURL);
    //       setImage(response.data);
    //     })
    //     .catch((error) => {
    //       setErrorImage(error);
    //       console.log(error);
    //     })
    //     .finally(() => {
    //       setLoadingImage(false);
    //     });
  };

  const handleCloseExtend = () => {
    setOpenExtend(false);
  };

  const handleCloseMenu = () => {
    setIsFromModal(false);
    setOpen(null);
    setDeletedItem(null);
    setDeletedItemId(null);
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const handleClickDelete = () => {
    if (isFromModal) {
      console.log('IsFromModal');
      axios
        .delete(`api/modal/${deletedItemId}`)
        .then()
        .catch((error) => { })
        .finally(() => {
          setOpenSnack(true);
          if (!error) {
            window.location.reload(false);
          }
        })
    } else {
      console.log('IsNotFromModal');
      axios
        .delete(`api/brand/${deletedItemId}`)
        .then()
        .catch((error) => { })
        .finally(() => {
          setOpenSnack(true);
          if (!error) {
            window.location.reload(false);
          }
        });
    }
    handleCloseDialog();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = BRANDLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - BRANDLIST.length) : 0;

  const filteredUsers = applySortFilter(BRANDLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return loading ? (
    <Box m={1} display="flex" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  ) : BRANDLIST ? (
    
    <>
      <Helmet>
        <title> Commande </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Commandes
          </Typography>
          
        </Stack>

        <Card>
          {/* <UserListHead
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            pageName="magasin"
          /> */}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={BRANDLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />

                <TableBody>
                  {BRANDLIST.length <= 0 ? (
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Il n'y a pas du Commandes
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  ) : (
                    filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                      const { id, first_name:Nom, last_name:Prenom, email, message } = row;
                      const selectedUser = selected.indexOf(id) !== -1;
                      // const date = Date(createAt);
                      return (
                        <>
                          <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                            <TableCell padding="checkbox">
                              <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, id)} />
                            </TableCell>
                          

                             <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                {/* <Avatar alt={name} src={avatarUrl} /> */}
                                
                                  {Nom}
                             
                              </Stack>
                            </TableCell> 
                            
                            <TableCell align="left">{Prenom}</TableCell>
                            <TableCell align="left">{email}</TableCell>
                            <TableCell align="left">{message}</TableCell>
                          

                            <TableCell align="right">
                              <IconButton

                                color="inherit"
                                onClick={(event) => handleOpenMenu(event, id)}
                              >
                                <Iconify icon={'eva:more-vertical-fill'} />
                              </IconButton>

                            </TableCell>


                            <Popover
                              open={Boolean(open)}
                              anchorEl={open}
                              onClose={handleCloseMenu}
                              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                              transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                              PaperProps={{
                                sx: {
                                  p: 1,
                                  width: 140,
                                  '& .MuiMenuItem-root': {
                                    px: 1,
                                    typography: 'body2',
                                    borderRadius: 0.75,
                                  },
                                },
                              }}
                            >
                              <MenuItem onClick={(event) => handleNavigate(event)}>
                                <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                                Edit
                              </MenuItem>

                              <MenuItem sx={{ color: 'error.main' }} onClick={(event) => handleOpenDialog(event)}>
                                <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                                Delete
                              </MenuItem>
                            </Popover>
                            <Dialog
                              open={openExtend}
                              onClose={handleCloseExtend}
                              aria-labelledby="alert-dialog-title"
                              aria-describedby="alert-dialog-description"
                            >
                              <DialogTitle id="alert-dialog-title">{deletedItem}</DialogTitle>
                              <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                  <Box sx={{
                                    width: '100%',
                                    height: '500px',
                                  }}>
                                    <img src={`https://lab.saroussa.com/${image}`} alt='brand' style={{
                                      maxWidth: '100%',
                                      maxHeight: '100%',
                                      width: 'auto',
                                      height: 'auto'
                                    }} />
                                  </Box>

                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleCloseExtend}>Fermer</Button>
                              </DialogActions>
                            </Dialog>
                            {error ? (
                              <Snackbar
                                open={openSnack}
                                autoHideDuration={6000}
                                onClose={handleCloseSnack}
                                anchorOrigin={{ vertical: 'buttom', horizontal: 'right' }}
                              >
                                <Alert
                                  variant="filled"
                                  onClose={handleCloseSnack}
                                  severity="error"
                                  sx={{ width: '100%' }}
                                >
                                  Erreur dans la suppression du {isFromModal ? "modéle" : "marque"}
                                </Alert>
                              </Snackbar>
                            ) : (
                              <Snackbar
                                open={openSnack}
                                autoHideDuration={6000}
                                onClose={handleCloseSnack}
                                anchorOrigin={{ vertical: 'buttom', horizontal: 'right' }}
                              >
                                <Alert
                                  variant="filled"
                                  onClose={handleCloseSnack}
                                  severity="success"
                                  sx={{ width: '100%' }}
                                >
                                  {isFromModal ? "le modéle" : "La marque"} a été suprimer avec succès.
                                </Alert>
                              </Snackbar>
                            )}
                          </TableRow>

                         
                        </>
                      );
                    })
                  )}

                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Introuvable
                          </Typography>

                          <Typography variant="body2">
                            Aucun résultat trouvé pour &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Essayez de vérifier les fautes de frappe ou d'utiliser des mots complets.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={BRANDLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  ) : (
    <h1>{error}</h1>
  );
}
