import {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
// @mui
import { Box, Card, Grid, Button, Stack, Container, Typography, CircularProgress } from '@mui/material';
// components
import Iconify from '../../components/iconify';
import { BlogPostCard, BlogPostsSort, BlogPostsSearch } from '../../sections/@dashboard/blog';

// hooks
import useFetch from '../../hooks/useFetch';

// ----------------------------------------------------------------------

const SORT_OPTIONS = [
  { value: 'latest', label: 'Latest' },
  { value: 'popular', label: 'Popular' },
  { value: 'oldest', label: 'Oldest' },
];

// ----------------------------------------------------------------------

export default function BlogPage() {
  const navigate = useNavigate();
  const [value, setValue] = useState(null);
  const onChangeVal = (val) => {
    console.log(val)
    setValue(val);
  };
  const handleNavigateAjouter = () => {
    navigate(`/blog/add`);
  };
  const { data, loading, error } = useFetch('/api/news');
  return loading ? (
    <Box m={1} display="flex" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  ) : data ? (
    <>
      <Helmet>
        <title> Dashboard: Blog </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Blog
          </Typography>
          <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={handleNavigateAjouter}>
            New Post
          </Button>
        </Stack>

        <Stack mb={5} direction="row" alignItems="center" justifyContent="space-between">
          <BlogPostsSearch posts={data} onChangeVal={onChangeVal} value = {value}/>
          {/* <BlogPostsSort options={SORT_OPTIONS} /> */}
        </Stack>

        <Grid container spacing={3}>
          {value?
          [value].map((post, index) => (
            <BlogPostCard key={post.id} post={post} index={index} />
          )):
           data.map((post, index) => (
            <BlogPostCard key={post.id} post={post} index={index} />
          ))}
        </Grid>
      </Container>
    </>
  ) : (
    <div>ERROR....</div>
  );
}
