import * as Yup from 'yup';
import { useEffect, useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Snackbar, Alert, Box, CircularProgress, Typography, Autocomplete, TextField, Button, Select, MenuItem } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// api
import axios from '../../../api/axios';
// components
import { FormProvider, RHFTextField, RHFFile, RHFSelect } from '../../../components/hook-form';
// hooks
import useFetch from '../../../hooks/useFetch';

// ----------------------------------------------------------------------


export default function RelationForm({membre,categories}) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

 
  const getAllCategories = (data) => {
    const cats = [];
    console.log(data)
    
      data.forEach((cat) => { 
        cat.subcategory.forEach((subCat)=>{
          cats.push(subCat);
        })

        
      });
   

    return cats;
  };

 

  const [cmdFormFields, setCmdFormFields] = useState([
    {
      categorie: "",
     
    },
  ]);

  const addCmdFields = () => {
    const object = {
      categorie: "",
    
    };

    setCmdFormFields([...cmdFormFields, object]);
  };

  const removeCmdFields = (index) => {
    const data = [...cmdFormFields];
    data.splice(index, 1);
    setCmdFormFields(data);
  };

  const handleChange = (event, index) => {
    const data = [...cmdFormFields];
    // console.log(data[index][event.target.name]);
    data[index][event.target.name] = event.target.value;
    setCmdFormFields(data);
   console.log(cmdFormFields)
  };

  
  const LoginSchema = Yup.object().shape({
    
  });

    
  const clean = (obj) => {
    Object.keys(obj).forEach((propName) =>{
    
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    })
    return obj
  }
  const defaultValues = {
    name: '',
    image: null,
  };

  const handleClose = () => {
    setOpen(false);
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
 

  const onSubmit = async (values) => {
    
    await cmdFormFields.forEach((categorie)=>{

       axios
      .post('api/addMarques', 
      {
        categoryId:categorie.categorie,
        marques:membre
      }, { mode: 'no-cors' })
      .then((res) => {
        setResponse(res);
        setError(null);
        // window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
        setResponse(null);
      })
      .finally(() => {
        setOpen(true);
      });

    }).then(()=>{
      window.location.reload(false);
    
    })
    
   
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {error ? (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert variant="filled" onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Logo existe déja.
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert variant="filled" onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Le logo a été ajouté avec succès.
          </Alert>
        </Snackbar>
      )}
      <Stack spacing={3}>
      {cmdFormFields.map((form, index1) => {
            return (
              <Box>
                <Stack direction="row" alignItems="center" m={3}>
                  <div>Catégorie : </div>
                  <Select
                    id="categorie"
                    value={form.stock}
                    onChange={(event) => handleChange(event, index1)}
                    label="Catégorie"
                    name="categorie"
                    style={{ width: "50%" }}
                  >
                    {getAllCategories(categories).map((category)=>{
                      return(
                        <MenuItem value={category.id}>{category.name}</MenuItem>
                      )
                    })}
                    
                   
                  </Select>
                  <Button
                    variant="contained"
                    color="warning"
                    style={{ margin: "7px" }}
                    state={{ from: "occupation" }}
                    onClick={(event) => removeCmdFields(event, index1)}
                  >
                    Enlever
                  </Button>
                </Stack>
              </Box>
            );
          })}
        
       
        <Button
          variant="contained"
          state={{ from: "occupation" }}
          onClick={addCmdFields}
        >
          Ajouter plus...
        </Button>
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Submit
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}

