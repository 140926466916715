import { useEffect, useState } from 'react';
import axios from '../api/axios';

export default function useFetch(url,param) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  useEffect(() => {
    if (!data) {
      setLoading(true);
      axios
        .get(url,param)
        .then((response) => {
          setData(response.data);
        })
        .catch((error) => {
          setError(error);
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return { data, loading, error };
}
