import { Helmet } from 'react-helmet-async';
import {useLocation} from 'react-router-dom';
// material
import {
  Box,
  Stack,
  Container,
  Typography,
  CircularProgress
} from '@mui/material';
// sections
import  UserForm  from '../../sections/@dashboard/user/UserForm';
// hooks
import useFetch from '../../hooks/useFetch';

// ----------------------------------------------------------------------




function AddUserPage({route,navigate}) {
  const location = useLocation();
 
  const { data: User, loading, error } = useFetch(`/api/user/${location?.state?.name}`);

  return (
    !loading?
    <>
      <Helmet>
        <title> Utilisateur </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            
            {location?.state?.name? `Modifier ${User?.first_name}` :"Ajouter un nouveau utilisateur"}
          </Typography>
        </Stack>
        <UserForm membre={location?.state?.name}/>
      </Container>
    </>
    :
    <Box m={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
  );
}

export default AddUserPage;