import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useState } from 'react';
// @mui
import {
  Box,
  Card,
  Table,
  Stack,
  styled,
  Paper,
  Avatar,
  Button,
  Switch,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar,
  Alert
} from '@mui/material';
// components
import Label from '../../components/label';
import Iconify from '../../components/iconify';
import Scrollbar from '../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../sections/@dashboard/user';
// hooks
import useFetch from '../../hooks/useFetch';
// api
import axios from '../../api/axios';
// mock
// import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'nom', label: 'Nom', alignRight: false },
  { id: 'prenom', label: 'Prénom', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'active', label: 'Active', alignRight: false },
  { id: 'telephone', label: 'Téléphone', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.first_name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {


  const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  const navigate = useNavigate();
  

  let USERLIST = [];
  const { data, loading, error } = useFetch('/api/list',{params:{roles:["ROLE_USER"]}});
  if (data) {
    USERLIST = data;

  }
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [response, setResponse] = useState(null);

  const [errorSwitch, setErrorSwitch] = useState(null);

  const [loadingSwitch, setLoadingSwitch] = useState(false);

  const [editedItemId, setEditedItemId] = useState(null);

  const [openSnack, setOpenSnack] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);

  const [deletedItem, setDeletedItem] = useState(null);

  const [deletedItemId, setDeletedItemId] = useState(null);

 
  const handleClickDelete = () => {
    axios.delete(`api/user/${editedItemId}`)
      .then()
      .catch(error => {

      })
      .finally(() => {
        setOpenSnack(true);
        if (!error) {
          window.location.reload(false);
        }

      });

    handleCloseDialog();
  }

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChangeSwitch = (event, id, active) => {

    console.log(id)
    setLoadingSwitch(true);
    axios
      .put(`api/user/${id}`, { is_active: (active === 0 ? "1" : "0") }, { headers: { "Content-Type": "application/x-www-form-urlencoded" } })
      .then((res) => {
        setResponse(res);
        setErrorSwitch(null);
        
      })
      .catch((error) => {
        setErrorSwitch(error);
        setResponse(null);
      })
      .finally(() => {
        setOpen(true);
        window.location.reload(false);
        setLoadingSwitch(false);

      });
  }

  const handleOpenMenu = (event, name, id) => {
    setOpen(event.currentTarget);
    setDeletedItem(name);
    setEditedItemId(id);
  };
  const handleEditItem = (event) => {
    navigate(`/user/edit/${editedItemId}`, { state: { name: editedItemId } });
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);


  const isNotFound = !filteredUsers.length && !!filterName;
  if (loading || loadingSwitch) {
    return (
      <Box m={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    )
  }
  return (
    <>
      <Helmet>
        <title> Utilisateur </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Utilisateur
          </Typography>
          <Button
            component={RouterLink}
            to="/user/add"
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}>
            Nouveau utilisateur
          </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} pageName="user" />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>

                  {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    const { id, first_name: first, last_name: last, email, is_active: active, phone, } = row;
                    const selectedUser = selected.indexOf(first) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        <TableCell padding="checkbox">
                          <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, first)} />
                        </TableCell>

                        <TableCell component="th" scope="row" padding="none">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            {/* <Avatar alt={first} src={avatarUrl} /> */}
                            <Typography variant="subtitle2" noWrap>
                              {first}
                            </Typography>
                          </Stack>
                        </TableCell>

                        <TableCell align="left">{last}</TableCell>


                        <TableCell align="left">{email}</TableCell>

                        <TableCell align="left">
                          <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} checked={active === 1} onChange={(event) => handleChangeSwitch(event, id, active)} />}
                          />
                        </TableCell>

                        <TableCell align="left">{phone}</TableCell>


                        <TableCell align="right">
                          <IconButton size="large" color="inherit" onClick={(event) => handleOpenMenu(event,first, id)}>
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Introuvable
                          </Typography>

                          <Typography variant="body2">
                            Aucun résultat trouvé pour &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Essayez de vérifier les fautes de frappe ou d'utiliser des mots complets.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={(event) => handleEditItem(event)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }} onClick={(event) => handleOpenDialog(event)}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Supprimer l'utilisateur {deletedItem}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Veuillez vraiment supprimer cet utilisateur
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button onClick={handleClickDelete} autoFocus>Accepter</Button>
        </DialogActions>
      </Dialog>
      {

        error ?
          <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack} anchorOrigin={{ vertical: 'buttom', horizontal: 'right' }}>
            <Alert variant="filled" onClose={handleCloseSnack} severity="error" sx={{ width: '100%' }}>
              Erreur dans la suppression d'utilisateur
            </Alert>
          </Snackbar>
          :
          <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack} anchorOrigin={{ vertical: 'buttom', horizontal: 'right' }}>
            <Alert variant="filled" onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
              L'utilisateur a été suprimer avec succès.
            </Alert>
          </Snackbar>

        }
    </>
  );

}








// import * as React from 'react';
// import { Helmet } from 'react-helmet-async';
// import { filter } from 'lodash';
// import { useState } from 'react';
// import { Link as RouterLink, useNavigate } from 'react-router-dom';
// // @mui
// import {
//   Box,
//   Card,
//   Table,
//   Stack,
//   Paper,
//   Button,
//   Popover,
//   Checkbox,
//   TableRow,
//   MenuItem,
//   TableBody,
//   TableCell,
//   Container,
//   Typography,
//   IconButton,
//   TableContainer,
//   TablePagination,
//   CircularProgress,
//   Dialog,
//   DialogContent,
//   DialogContentText,
//   DialogActions,
//   DialogTitle,
//   Snackbar,
//   Alert,
//   Collapse,
//   TableHead
// } from '@mui/material';
// // components
// import Iconify from '../components/iconify';
// import Scrollbar from '../components/scrollbar';
// // sections
// import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// // hooks
// import useFetch from '../hooks/useFetch';
// // api
// import axios from '../api/axios';

// // ----------------------------------------------------------------------

// const TABLE_HEAD = [
//   { id: 'name', label: 'Name', alignRight: false },
//   { id: 'company', label: 'Company', alignRight: false },
//   { id: 'role', label: 'Role', alignRight: false },
//   { id: 'isVerified', label: 'Verified', alignRight: false },
//   { id: 'status', label: 'Status', alignRight: false },
//   { id: '' },
// ];

// // ----------------------------------------------------------------------

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === 'desc'
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

// export default function UserPage() {
//   const navigate = useNavigate();
//   let USERLIST = [];
//   const { data, loading, error } = useFetch('/api/user');
//   if (data) {
//     USERLIST = data;
//   }
//   const [open, setOpen] = useState(null);

//   const [openModal, setOpenModal] = useState(null);

//   const [isFromModal, setIsFromModal] = useState(false);

//   const [page, setPage] = useState(0);

//   const [order, setOrder] = useState('asc');

//   const [selected, setSelected] = useState([]);

//   const [orderBy, setOrderBy] = useState('name');

//   const [filterName, setFilterName] = useState('');

//   const [rowsPerPage, setRowsPerPage] = useState(5);

//   const [openSnack, setOpenSnack] = useState(false);

//   const [openDialog, setOpenDialog] = useState(false);

//   const [deletedItem, setDeletedItem] = useState(null);

//   const [deletedItemId, setDeletedItemId] = useState(null);

//   const handleNavigate = () => {
//     navigate(`/team/add/${deletedItemId}`, { state: { name: deletedItemId } });
//   };

//   const handleOpenDialog = () => {
//     setOpenDialog(true);
//   };

//   const handleCloseDialog = () => {
//     setOpenDialog(false);
//   };

//   const handleOpenMenu = (event, name, id, isModal) => {
//     if(isModal === true){
//       setIsFromModal(true);
//     }
//     setDeletedItem(name);
//     setDeletedItemId(id);
//     setOpen(event.currentTarget);
//   };

//   const handleCloseMenu = () => {
//     setIsFromModal(false);
//     setOpen(null);
//     setDeletedItem(null);
//     setDeletedItemId(null);
//   };

//   const handleCloseSnack = () => {
//     setOpenSnack(false);
//   };

//   const handleClickDelete = () => {
//     if(isFromModal){
//       console.log('IsFromModal');
//       axios
//     .delete(`api/modal/${deletedItemId}`)
//     .then()
//     .catch((error) => { })
//     .finally(() => {
//       setOpenSnack(true);
//       if (!error) {
//         window.location.reload(false);
//       }
//     })
//     }else{
//       console.log('IsNotFromModal');
//       axios
//       .delete(`api/brand/${deletedItemId}`)
//       .then()
//       .catch((error) => { })
//       .finally(() => {
//         setOpenSnack(true);
//         if (!error) {
//           window.location.reload(false);
//         }
//       });
//     }
//     handleCloseDialog();
//   };

//   const handleRequestSort = (event, property) => {
//     const isAsc = orderBy === property && order === 'asc';
//     setOrder(isAsc ? 'desc' : 'asc');
//     setOrderBy(property);
//   };

//   const handleSelectAllClick = (event) => {
//     if (event.target.checked) {
//       const newSelecteds = USERLIST.map((n) => n.name);
//       setSelected(newSelecteds);
//       return;
//     }
//     setSelected([]);
//   };

//   const handleClick = (event, name) => {
//     const selectedIndex = selected.indexOf(name);
//     let newSelected = [];
//     if (selectedIndex === -1) {
//       newSelected = newSelected.concat(selected, name);
//     } else if (selectedIndex === 0) {
//       newSelected = newSelected.concat(selected.slice(1));
//     } else if (selectedIndex === selected.length - 1) {
//       newSelected = newSelected.concat(selected.slice(0, -1));
//     } else if (selectedIndex > 0) {
//       newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
//     }
//     setSelected(newSelected);
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setPage(0);
//     setRowsPerPage(parseInt(event.target.value, 10));
//   };

//   const handleFilterByName = (event) => {
//     setPage(0);
//     setFilterName(event.target.value);
//   };

//   const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

//   const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

//   const isNotFound = !filteredUsers.length && !!filterName;

//   return loading ? (
//     <Box m={1} display="flex" justifyContent="center" alignItems="center">
//       <CircularProgress />
//     </Box>
//   ) : USERLIST ? (
//     <>
//       <Helmet>
//         <title> Utilisateur </title>
//       </Helmet>

//       <Container>
//         <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
//           <Typography variant="h4" gutterBottom>
//           Utilisateur
//           </Typography>
//           <Button
//             variant="contained"
//             component={RouterLink}

//             to="/team/add"
//             state={{ from: 'occupation' }}
//             startIcon={<Iconify icon="eva:plus-fill" />}
//           >
//             Nouveau Utilisateur
//           </Button>
//         </Stack>

//         <Card>
//           {/* <UserListHead
//             numSelected={selected.length}
//             filterName={filterName}
//             onFilterName={handleFilterByName}
//             pageName="magasin"
//           /> */}

//           <Scrollbar>
//             <TableContainer sx={{ minWidth: 800 }}>
//               <Table>
//                 <UserListHead
//                   order={order}
//                   orderBy={orderBy}
//                   headLabel={TABLE_HEAD}
//                   rowCount={USERLIST.length}
//                   numSelected={selected.length}
//                   onRequestSort={handleRequestSort}
//                   onSelectAllClick={handleSelectAllClick}
//                 />

//                 <TableBody>
//                   {USERLIST.length <= 0 ? (
//                     <TableRow>
//                       <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
//                         <Paper
//                           sx={{
//                             textAlign: 'center',
//                           }}
//                         >
//                           <Typography variant="h6" paragraph>
//                             Il n'y a pas du Marque
//                           </Typography>
//                         </Paper>
//                       </TableCell>
//                     </TableRow>
//                   ) : (
//                     filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
//                       const { id, name, modal } = row;
//                       const selectedUser = selected.indexOf(name) !== -1;

//                       return (
//                         <>
//                           <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
//                             <TableCell padding="checkbox">
//                               <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
//                             </TableCell>
//                             <TableCell padding='checkbox'>
//                               <IconButton
//                                 aria-label="expand row"
//                                 size="small"
//                                 onClick={() => setOpenModal(!openModal)}
//                               >
//                                 {openModal ? <Iconify
//                                   icon="ic:baseline-chevron-right"
//                                   sx={{
//                                     color: 'common.black',
//                                   }}
//                                 /> : <Iconify
//                                   icon="ic:outline-expand-more"
//                                   sx={{
//                                     color: 'common.black',
//                                   }}
//                                 />}
//                               </IconButton>
//                             </TableCell>

//                             <TableCell component="th" scope="row" padding="none">
//                               <Stack direction="row" alignItems="center" spacing={2}>
//                                 {/* <Avatar alt={name} src={avatarUrl} /> */}
//                                 <Typography variant="subtitle2" noWrap>
//                                   {name}
//                                 </Typography>
//                               </Stack>
//                             </TableCell>

//                             <TableCell align="right">
//                               <IconButton
//                                 size="large"
//                                 color="inherit"
//                                 onClick={(event) => handleOpenMenu(event, name, id)}
//                               >
//                                 <Iconify icon={'eva:more-vertical-fill'} />
//                               </IconButton>
//                             </TableCell>
//                             <Popover
//                               open={Boolean(open)}
//                               anchorEl={open}
//                               onClose={handleCloseMenu}
//                               anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
//                               transformOrigin={{ vertical: 'top', horizontal: 'right' }}
//                               PaperProps={{
//                                 sx: {
//                                   p: 1,
//                                   width: 140,
//                                   '& .MuiMenuItem-root': {
//                                     px: 1,
//                                     typography: 'body2',
//                                     borderRadius: 0.75,
//                                   },
//                                 },
//                               }}
//                             >
//                               <MenuItem onClick={(event) => handleNavigate(event)}>
//                                 <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
//                                 Edit
//                               </MenuItem>

//                               <MenuItem sx={{ color: 'error.main' }} onClick={(event) => handleOpenDialog(event)}>
//                                 <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
//                                 Delete
//                               </MenuItem>
//                             </Popover>
//                             <Dialog
//                               open={openDialog}
//                               onClose={handleCloseDialog}
//                               aria-labelledby="alert-dialog-title"
//                               aria-describedby="alert-dialog-description"
//                             >
//                               <DialogTitle id="alert-dialog-title">Supprimer {isFromModal? "le modéle":" la marque"} {deletedItem}</DialogTitle>
//                               <DialogContent>
//                                 <DialogContentText id="alert-dialog-description">
//                                   Veuillez vraiment supprimer {isFromModal? "ce modéle":" cette marque"}
//                                 </DialogContentText>
//                               </DialogContent>
//                               <DialogActions>
//                                 <Button onClick={handleCloseDialog}>Annuler</Button>
//                                 <Button onClick={handleClickDelete} autoFocus>
//                                   Accepter
//                                 </Button>
//                               </DialogActions>
//                             </Dialog>
//                             {error ? (
//                               <Snackbar
//                                 open={openSnack}
//                                 autoHideDuration={6000}
//                                 onClose={handleCloseSnack}
//                                 anchorOrigin={{ vertical: 'buttom', horizontal: 'right' }}
//                               >
//                                 <Alert
//                                   variant="filled"
//                                   onClose={handleCloseSnack}
//                                   severity="error"
//                                   sx={{ width: '100%' }}
//                                 >
//                                   Erreur dans la suppression du {isFromModal? "modéle":"marque"}
//                                 </Alert>
//                               </Snackbar>
//                             ) : (
//                               <Snackbar
//                                 open={openSnack}
//                                 autoHideDuration={6000}
//                                 onClose={handleCloseSnack}
//                                 anchorOrigin={{ vertical: 'buttom', horizontal: 'right' }}
//                               >
//                                 <Alert
//                                   variant="filled"
//                                   onClose={handleCloseSnack}
//                                   severity="success"
//                                   sx={{ width: '100%' }}
//                                 >
//                                  {isFromModal? "le modéle":"La marque"} a été suprimer avec succès.
//                                 </Alert>
//                               </Snackbar>
//                             )}
//                           </TableRow>

//                           {/* collapdes row */}

//                           <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
//                             <Collapse in={openModal} timeout="auto" unmountOnExit>
//                               <Box sx={{ margin: 1 }}>
//                                 <Typography variant="h6" gutterBottom component="div">
//                                   Modèle
//                                 </Typography>
//                                 <Table size="small" aria-label="purchases">
//                                   <TableHead>
//                                     <TableRow>
//                                       <TableCell>Nom</TableCell>
//                                       <TableCell>Année</TableCell>
//                                       <TableCell/>
//                                     </TableRow>
//                                   </TableHead>
//                                   <TableBody>
//                                     {modal.map((mod) => (
//                                       <TableRow key={mod.id}>
//                                         <TableCell component="th" scope="row">
//                                           {mod.name}
//                                         </TableCell>
//                                         <TableCell>{mod.year}</TableCell>
//                                         <TableCell align="right">
//                               <IconButton
//                                 size="large"
//                                 color="inherit"
//                                 onClick={(event) => handleOpenMenu(event, mod.name, mod.id,true)}
//                               >
//                                 <Iconify icon={'eva:more-vertical-fill'} />
//                               </IconButton>
//                             </TableCell>
//                                         <Popover
//                               open={Boolean(open)}
//                               anchorEl={open}
//                               onClose={handleCloseMenu}
//                               anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
//                               transformOrigin={{ vertical: 'top', horizontal: 'right' }}
//                               PaperProps={{
//                                 sx: {
//                                   p: 1,
//                                   width: 140,
//                                   '& .MuiMenuItem-root': {
//                                     px: 1,
//                                     typography: 'body2',
//                                     borderRadius: 0.75,
//                                   },
//                                 },
//                               }}
//                             >
//                               <MenuItem onClick={(event) => handleNavigate(event)}>
//                                 <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
//                                 Edit
//                               </MenuItem>

//                               <MenuItem sx={{ color: 'error.main' }} onClick={(event) => handleOpenDialog(event)}>
//                                 <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
//                                 Delete
//                               </MenuItem>
//                             </Popover>
//                                       </TableRow>
//                                     ))}
//                                   </TableBody>
//                                 </Table>
//                               </Box>
//                             </Collapse>
//                           </TableCell>
//                         </>
//                       );
//                     })
//                   )}

//                   {emptyRows > 0 && (
//                     <TableRow style={{ height: 53 * emptyRows }}>
//                       <TableCell colSpan={6} />
//                     </TableRow>
//                   )}
//                 </TableBody>

//                 {isNotFound && (
//                   <TableBody>
//                     <TableRow>
//                       <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
//                         <Paper
//                           sx={{
//                             textAlign: 'center',
//                           }}
//                         >
//                           <Typography variant="h6" paragraph>
//                             Introuvable
//                           </Typography>

//                           <Typography variant="body2">
//                             Aucun résultat trouvé pour &nbsp;
//                             <strong>&quot;{filterName}&quot;</strong>.
//                             <br /> Essayez de vérifier les fautes de frappe ou d'utiliser des mots complets.
//                           </Typography>
//                         </Paper>
//                       </TableCell>
//                     </TableRow>
//                   </TableBody>
//                 )}
//               </Table>
//             </TableContainer>
//           </Scrollbar>

//           <TablePagination
//             rowsPerPageOptions={[5, 10, 25]}
//             component="div"
//             count={USERLIST.length}
//             rowsPerPage={rowsPerPage}
//             page={page}
//             onPageChange={handleChangePage}
//             onRowsPerPageChange={handleChangeRowsPerPage}
//           />
//         </Card>
//       </Container>
//     </>
//   ) : (
//     <h1>{error}</h1>
//   );
// }
