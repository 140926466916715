import PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  Link,
  Card,
  Grid,
  Avatar,
  Typography,
  CardContent,
  Chip,
  Stack,
  Tooltip,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Snackbar, 
  Alert
} from '@mui/material';
// api
import axios from '../../../api/axios';
// utils
import { fDate } from '../../../utils/formatTime';
import { fShortenNumber } from '../../../utils/formatNumber';
//
import SvgColor from '../../../components/svg-color';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledCardMedia = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)',
});

const StyledTitle = styled(Link)({
  height: 44,
  overflow: 'hidden',
  WebkitLineClamp: 2,
  display: '-webkit-box',
  WebkitBoxOrient: 'vertical',
});

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  left: theme.spacing(3),
  bottom: theme.spacing(-2),
}));

const StyledInfo = styled('div')(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  marginTop: theme.spacing(3),
  color: theme.palette.text.disabled,
}));

const StyledCover = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

BlogPostCard.propTypes = {
  post: PropTypes.object.isRequired,
  index: PropTypes.number,
};

export default function BlogPostCard({ post, index }) {
  const [showIcons, setShowIcons] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  function handleNavigate() {
    navigate(`/blog/${post.id}`, { state: { name: post.id } });
  }

  const handleNavigateSupprimer = () => {
    // navigate(`/blog/${post.id}`, { state: { name: post.id } });
    setOpenDialog(true);
  };
  const handleNavigateModifier = () => {
    navigate(`/blog/edit/${post.id}`, { state: { name: post.id } });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const handleCloseSnack = () => {
    setOpenSnack(false);
  };
  const handleClickDelete = () => {
    axios
      .delete(`api/news/${post.id}`)
      .then()
      .catch((error) => {setError(error)})
      .finally(() => {
        setOpenSnack(true);
        if (!error) {
          window.location.reload(false);
        }
      });

    handleCloseDialog();
  };

  const { image, title, text, comment, isQFP, share, author = 'SAROUSSA', created_at: createdAt } = post;
  const latestPostLarge = index === 0;
  const latestPost = index === 1 || index === 2;

  const POST_INFO = [
    { text: 'supprimer', icon: 'eva:trash-fill', color: '#FF0000', handleNavigate: { handleNavigateSupprimer } },
    { text: 'modifier', icon: 'eva:edit-fill', color: '#FFA500', handleNavigate: { handleNavigateModifier } },
    { text: 'afficher', icon: 'eva:eye-fill', color: '#1877F2', handleNavigate: { handleNavigate } },
  ];

  return (
    <Grid item xs={12} sm={latestPostLarge ? 12 : 6} md={latestPostLarge ? 6 : 3}>
      <Card
        sx={{ position: 'relative' }}
        // onClick={() => {
        //   handleNavigate();
        // }}
        onMouseEnter={() => {
          setShowIcons(true);
        }}
        onMouseLeave={() => {
          setShowIcons(false);
        }}
      >
        <StyledCardMedia
          sx={{
            ...((latestPostLarge || latestPost) && {
              pt: 'calc(100% * 4 / 3)',
              '&:after': {
                top: 0,
                content: "''",
                width: '100%',
                height: '100%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
              },
            }),
            ...(latestPostLarge && {
              pt: {
                xs: 'calc(100% * 4 / 3)',
                sm: 'calc(100% * 3 / 4.66)',
              },
            }),
          }}
        >
          <SvgColor
            color="paper"
            src="/assets/icons/shape-avatar.svg"
            sx={{
              width: 80,
              height: 36,
              zIndex: 9,
              bottom: -15,
              position: 'absolute',
              color: 'background.paper',
              ...((latestPostLarge || latestPost) && { display: 'none' }),
            }}
          />
          <StyledAvatar
            alt={author.name}
            src={author.avatarUrl}
            sx={{
              ...((latestPostLarge || latestPost) && {
                zIndex: 9,
                top: 24,
                left: 24,
                width: 40,
                height: 40,
              }),
            }}
          />

          <StyledCover alt={title} src={process.env.REACT_APP_PUBLIC_URL + image} />
        </StyledCardMedia>

        <CardContent
          sx={{
            pt: 4,
            ...((latestPostLarge || latestPost) && {
              bottom: 0,
              width: '100%',
              position: 'absolute',
            }),
          }}
        >
          <Typography gutterBottom variant="caption" sx={{ color: 'text.disabled', display: 'block' }}>
            {fDate(createdAt)}
          </Typography>

          <StyledTitle
            color="inherit"
            variant="subtitle2"
            underline="hover"
            sx={{
              ...(latestPostLarge && { typography: 'h5', height: 60 }),
              ...((latestPostLarge || latestPost) && {
                color: 'common.white',
              }),
            }}
          >
            {title}
          </StyledTitle>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* {POST_INFO.map((info, index) => (
                  <Box
                    key={index}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      ml: index === 0 ? 0 : 1.5,
                      ...((latestPostLarge || latestPost) && {
                        color: 'grey.500',
                      }),
                    }}
                  >
                    <Iconify icon={info.icon} sx={{ width: 16, height: 16, mr: 0.5 }} />
                    <Typography variant="caption">{fShortenNumber(info.number)}</Typography>
                  </Box>
                ))} */}

            {/* <StyledInfo>
              {isQFP === 1 ? (
                <Chip label="QFP" color="primary" />
              ) : (
                // <div>QFP</div>
                <Chip label="NEWS" color="warning" />
              )}
            </StyledInfo> */}

            <StyledInfo>
              {isQFP === 1 ? (
                <Chip label="QFP" color="primary" />
              ) : (
                // <div>QFP</div>
                <Chip label="NEWS" color="warning" />
              )}
            </StyledInfo>
            {showIcons ? (
              <StyledInfo>
                {POST_INFO.map((info, index) => (
                  <Tooltip
                    key={index}
                    title={info.text}
                    onClick={
                      index === 0 ? handleNavigateSupprimer : index === 1 ? handleNavigateModifier : handleNavigate
                    }
                  >
                    <Avatar color="primary" sx={{ bgcolor: info.color }}>
                      <IconButton color="inherit">
                        <Iconify icon={info.icon} sx={{ width: 16, height: 16 }} />
                      </IconButton>
                    </Avatar>
                  </Tooltip>
                ))}
              </StyledInfo>
            ) : null}
          </div>
        </CardContent>
      </Card>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Supprimer l'événement {post.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Veuillez vraiment supprimer cet article</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Annuler</Button>
          <Button onClick={handleClickDelete} autoFocus>
            Accepter
          </Button>
        </DialogActions>
      </Dialog>
      {error ? (
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: 'buttom', horizontal: 'right' }}
        >
          <Alert variant="filled" onClose={handleCloseSnack} severity="error" sx={{ width: '100%' }}>
            Erreur dans la suppression du événement
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleCloseSnack}
          anchorOrigin={{ vertical: 'buttom', horizontal: 'right' }}
        >
          <Alert variant="filled" onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
            L'événement' a été suprimer avec succès.
          </Alert>
        </Snackbar>
      )}
    </Grid>
  );
}
