import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

RHFTextField.propTypes = {
  name: PropTypes.string,
};

export default function RHFTextField({ name,value,size,variant,multiline,...other }) {
  const { control } = useFormContext();
  return (
    <Controller 
      
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          type
          {...field}
           size={size}
           variant={variant}
           fullWidth
           value={typeof field.value === 'number' && field.value === 0 ? '':name==='parent'? value: field.value }
           error={!!error}
           helperText={error?.message}
           
           rows={multiline?7:1}
           {...other}
        />
      )}
    />
    
  );
}
