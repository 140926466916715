import * as Yup from 'yup';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Snackbar, Alert, Box, CircularProgress, Typography, Autocomplete, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// api
import axios from '../../../api/axios';
// components
import { FormProvider, RHFTextField, RHFFile, RHFSelect } from '../../../components/hook-form';
// hooks
import useFetch from '../../../hooks/useFetch';

// ----------------------------------------------------------------------


export default function LogoForm({membre}) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  
  const LoginSchema = Yup.object().shape({
    name: membre?Yup.string():Yup.string().required('Nom obligatoire'),
    image:membre? Yup.mixed().notRequired(): Yup.mixed().test('fileExist',"Image obligatoire",(value)=>{
      return  value && value?.name
    })
    .test('type',"Image extension invalid",(value)=>{
     
      return value && (value.type==="image/png" || value.type==="image/jpeg" || value.type === "image/svg+xml")
    })
    .test('fileSize',"La taille de fichier est trop volumineux",(value)=>{ 
      return value && value.size <= 2000000
    }),
  });

    
  const clean = (obj) => {
    Object.keys(obj).forEach((propName) =>{
    
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    })
    return obj
  }
  const defaultValues = {
    name: '',
    image: null,
  };

  const handleClose = () => {
    setOpen(false);
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;
 

  const onSubmit = async (values) => {
    
    if(membre){
      
  // if(values.image!=null){
    
  //   axios
  //     .post('api/marqueFile', {image:values.image}, { mode: 'no-cors' })
  //     .then((res) => {
  //       setResponse(res);
  //       setError(null);
  //       values.image = res.data;

  //       console.log(values.image);
        


  //       // window.location.reload(false);
  //     })
  //     .catch((error) => {
        
  //       setError(error);
  //       setResponse(null);
  //     })
  //     .finally(() => {
  //       // setOpen(true);

  //       axios
  //       .put(`api/marque/${membre}`, clean(values),{headers:{"Content-Type":"application/json"}} )
  //       .then((res) => {
  //         setResponse(res);
  //         setError(null);
  //         window.location.reload(false);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         setError(error);
  //         setResponse(null);
  //       })
  //       .finally(() => {
  //         setOpen(true);
  //       });
  //     });
  // }else{
    console.log("here");
    axios
    .put(`api/marque/${membre}`, clean(values),{ mode: 'no-cors' } )
    .then((res) => {
      setResponse(res);
      setError(null);
      window.location.reload(false);
    })
    .catch((error) => {
      console.log(error);
      setError(error);
      setResponse(null);
    })
    .finally(() => {
      setOpen(true);
    });
  
  // }
}else{
    
    axios
      .post('api/marque', values, { mode: 'no-cors' })
      .then((res) => {
        setResponse(res);
        setError(null);
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
        setResponse(null);
      })
      .finally(() => {
        setOpen(true);
      });
    }
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {error ? (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert variant="filled" onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Logo existe déja.
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert variant="filled" onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Le logo a été ajouté avec succès.
          </Alert>
        </Snackbar>
      )}
      <Stack spacing={3}>
        <RHFTextField name="name" label="Nom" />
        <RHFFile name="image" label="Image" />
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Submit
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}

