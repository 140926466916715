import { Helmet } from 'react-helmet-async';
import {useLocation} from 'react-router-dom';
// material
import {
  Box,
  Stack,
  Container,
  Typography,
  CircularProgress
} from '@mui/material';
// sections
import  PromotionForm  from '../../../sections/@dashboard/siteSection/PromotionForm';
// hooks
import useFetch from '../../../hooks/useFetch';

// ----------------------------------------------------------------------




function AddPromotionPage({route,navigate}) {
  const location = useLocation();
  console.log(location.state.name)
  const { data: Promotion, loading, error } = useFetch(`/api/promotion`,{params:{promotionId:location.state.name}});
  // console.log(Team);
  return (
    Promotion?
    <>
      <Helmet>
        <title> Promotions </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            
            {location.state.name? `Modifier la promotion` :"Ajouter une nouvelle promotion"}
          </Typography>
        </Stack>
        <PromotionForm membre={Promotion}/>
      </Container>
    </>
    :
    <Box m={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
  );
}

export default AddPromotionPage;

