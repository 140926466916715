import { Helmet } from 'react-helmet-async';
import {useLocation} from 'react-router-dom';
// material
import {
  Box,
  Stack,
  Container,
  Typography,
  CircularProgress
} from '@mui/material';
// sections
import  HeroForm  from '../../../sections/@dashboard/siteSection/HeroForm';
// hooks
import useFetch from '../../../hooks/useFetch';
// ----------------------------------------------------------------------




export default function AddHeroPage() {
  const location = useLocation();
  const { data: Hero, loading, error } = useFetch(`/api/hero`,{params:{heroId:location.state.name}});

  return (
    Hero?
    <>
      <Helmet>
        <title> Hero </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {location.state.name? `Modifier ${Hero.title}` :"Ajouter un nouveau element"}
          </Typography>
        </Stack>
        <HeroForm membre={location.state.name?Hero:null}/>
      </Container>
    </>
    : <Box m={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
  );

}



