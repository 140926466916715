import { Helmet } from 'react-helmet-async';
import {useLocation} from 'react-router-dom';
// material
import {
  Box,
  Stack,
  Container,
  Typography,
  CircularProgress
} from '@mui/material';
// sections
import NewsForm from '../../sections/@dashboard/question/NewsForm';
// hooks
import useFetch from '../../hooks/useFetch';


// ----------------------------------------------------------------------




function AnswerPage({route,navigate}) {
  const location = useLocation();
  const { data: Question, loading, error } = useFetch(`/api/question`,{params:{questionId:location.state.name}});

  return (
    Question?
    <>
      <Helmet>
        <title> Réponse </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            
            {`Répondez à la question de ${Question.first_name}`}
          </Typography>
        </Stack>
        <NewsForm membre={location.state.name}/>
      </Container>
    </>
    :
    <Box m={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
  );
}

export default AnswerPage;