import * as Yup from 'yup';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Snackbar, Alert, Box, CircularProgress, Typography, Autocomplete, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// api
import axios from '../../../api/axios';
// components
import { FormProvider, RHFTextField, RHFFile, RHFSelect, RHFCheckbox } from '../../../components/hook-form';
// hooks
import useFetch from '../../../hooks/useFetch';

// ----------------------------------------------------------------------

export default function HeroForm({ membre }) {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  const LoginSchema = Yup.object().shape({
    title: Yup.string().required('titre obligatoire'),
    sub_title: Yup.string().required('sous titre obligatoire'),
    description: Yup.string().required('description obligatoire'),
    title_english: Yup.string().required('titre obligatoire'),
    sub_title_english: Yup.string().required('sous titre obligatoire'),
    description_english: Yup.string().required('description obligatoire'),
    title_arabic: Yup.string().required('titre obligatoire'),
    sub_title_arabic: Yup.string().required('sous titre obligatoire'),
    description_arabic: Yup.string().required('description obligatoire'),
    image: membre
      ? Yup.mixed().notRequired()
      : Yup.mixed()
          .test('fileExist', 'Image obligatoire', (value) => {
            return value && value?.name;
          })
          .test('type', 'Image extension invalid', (value) => {
            return (
              value && (value.type === 'image/png' || value.type === 'image/jpeg' || value.type === 'image/svg+xml')
            );
          })
          .test('fileSize', 'La taille de fichier est trop volumineux', (value) => {
            return value && value.size <= 20000000;
          }),
  });

  const clean = (obj) => {
    Object.keys(obj).forEach((propName) => {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    });
    return obj;
  };
  const defaultValues = {
    title: membre ? membre.title : '',
    sub_title: membre ? membre.sub_title : '',
    description: membre ? membre.description : '',
    title_english: membre ? membre.title_english : '',
    sub_title_english: membre ? membre.sub_title_english : '',
    description_english: membre ? membre.description_english : '',
    title_arabic: membre ? membre.title_arabic : '',
    sub_title_arabic: membre ? membre.sub_title_arabic : '',
    description_arabic: membre ? membre.description_arabic : '',
    image: null,
  };

  const handleClose = () => {
    setOpen(false);
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (values) => {
    if (membre) {
      if (values.image != null) {
        axios
          .post('api/heroFile', { image: values.image }, { mode: 'no-cors' })
          .then((res) => {
            setResponse(res);
            setError(null);
            // setImage(res.data);
            // console.log(res.data);
            // window.location.reload(false);
          })
          .catch((error) => {
            setError(error);
            setResponse(null);
          })
          .finally(() => {
            values.image = `img/hero/${values.image.name}`;

            const val = clean(values);
            axios
              .put(`api/hero/${membre.id}`, val, { headers: { 'Content-Type': 'application/json' } })
              .then((res) => {
                setResponse(res);
                setError(null);
                window.location.reload(false);
              })
              .catch((error) => {
                console.log(error);
                setError(error);
                setResponse(null);
              })
              .finally(() => {
                setOpen(true);
              });
          });
      } else {
        axios
          .put(`api/hero/${membre.id}`, clean(values), {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          })
          .then((res) => {
            setResponse(res);
            setError(null);
            window.location.reload(false);
          })
          .catch((error) => {
            console.log(error);
            setError(error);
            setResponse(null);
          })
          .finally(() => {
            setOpen(true);
          });
      }
    } else {
      axios
        .post(`api/hero`, values, { mode: 'no-cors' })
        .then((res) => {
          setResponse(res);
          setError(null);
          window.location.reload(false);
        })
        .catch((error) => {
          console.log(error);
          setError(error);
          setResponse(null);
        })
        .finally(() => {
          setOpen(true);
        });
    }
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {error ? (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert variant="filled" onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Elément existe déja.
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert variant="filled" onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            L'élément a été ajouté avec succès.
          </Alert>
        </Snackbar>
      )}
      <Stack spacing={3}>
        <RHFTextField name="title" label="Grand titre" />
        <RHFTextField name="sub_title" label="Sous titre" />
        <RHFTextField name="description" label="Description" />
        <RHFTextField name="title_english" label="Grand titre anglais" />
        <RHFTextField name="sub_title_english" label="Sous titre anglais" />
        <RHFTextField name="description_english" label="Description anglais" />
        <RHFTextField name="title_arabic" label="Grand titre arab" dir="rtl" />
        <RHFTextField name="sub_title_arabic" label="Sous titre arab" dir="rtl" />
        <RHFTextField name="description_arabic" label="Description arab" dir="rtl" />
        <RHFFile name="image" label="Image" />
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Submit
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
