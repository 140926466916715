import * as Yup from 'yup';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Snackbar, Alert, Box, CircularProgress, Typography, Autocomplete, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// api
import axios from '../../../api/axios';
// components
import { FormProvider, RHFTextField, RHFFile, RHFSelect } from '../../../components/hook-form';
// hooks
import useFetch from '../../../hooks/useFetch';

// ----------------------------------------------------------------------
export default function UserHelpForm({membre}) {
  
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  const LoginSchema = Yup.object().shape({
    title:membre?Yup.string(): Yup.string().required('Titre obligatoire'),
    text:membre?Yup.string():Yup.string().required('Text obligatoire'),
    title_english:membre?Yup.string():Yup.string().required('Titre obligatoire'),
    text_english:membre?Yup.string():Yup.string().required('Text obligatoire'),
    title_arabic:membre?Yup.string():Yup.string().required('Titre obligatoire'),
    text_arabic:membre?Yup.string():Yup.string().required('Text obligatoire'),
  
  });

  const clean = (obj) => {
    Object.keys(obj).forEach((propName) =>{
    
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    })
    return obj
  }

  const defaultValues = {
    title: '',
    text: '',
    title_english: '',
    text_english: '',
    title_arabic: '',
    text_arabic: '',
  };

  const handleClose = () => {
    setOpen(false);
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (values) => {

    if(membre){
    axios
    .put(`api/userHelp/${membre}`, clean(values),{headers:{"Content-Type":"application/x-www-form-urlencoded"}} )
    .then((res) => {
      setResponse(res);
      setError(null);
      window.location.reload(false);
    })
    .catch((error) => {
      console.log(error);
      setError(error);
      setResponse(null);
    })
    .finally(() => {
      setOpen(true);
    });
  
}else{
    
    axios
      .post('api/userHelp', values, { mode: 'no-cors' })
      .then((res) => {
        setResponse(res);
        setError(null);
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
        setResponse(null);
      })
      .finally(() => {
        setOpen(true);
      });
    }
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {error ? (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert variant="filled" onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Aide existe déja.
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert variant="filled" onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            L'aide a été ajouté avec succès.
          </Alert>
        </Snackbar>
      )}
      <Stack spacing={3}>
        <RHFTextField name="title" label="Titre" />
        <RHFTextField name="text" label="Text" />
        <RHFTextField name="title_english" label="Titre anglais" />
        <RHFTextField name="text_english" label="Text anglais" />
        <RHFTextField name="title_arabic" label="Titre arabe" dir="rtl"/>
        <RHFTextField name="text_arabic" label="Text arabe" dir="rtl"/>
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Submit
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}

