import { Helmet } from 'react-helmet-async';
import {useLocation} from 'react-router-dom';
// material
import {
  Box,
  Stack,
  Container,
  Typography,
  CircularProgress
} from '@mui/material';
// sections
import  BlogForm  from '../../sections/@dashboard/blog/BlogForm';
// hooks
import useFetch from '../../hooks/useFetch';
// ----------------------------------------------------------------------




function AddBlogPage() {
  const location = useLocation();
  const { data: Blog, loading, error } = useFetch(`/api/news`,{params:{newsId:location?.state?.name}});
console.log(Blog)
  return (
    Blog?
    <>
      <Helmet>
        <title> Blog </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {location?.state?.name? `Modifier ${Blog.title}` :"Ajouter un nouveau article"}
          </Typography>
        </Stack>
        <BlogForm membre={location?.state?.name?Blog:null} question = {location?.state?.question}/>
      </Container>
    </>
    : <Box m={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
  );

}

export default AddBlogPage;

