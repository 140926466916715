import * as Yup from 'yup';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Stack, Snackbar, Alert, Box, CircularProgress, Typography, Autocomplete, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// api
import axios from '../../../api/axios';
// components
import { FormProvider, RHFTextField, RHFFile, RHFSelect } from '../../../components/hook-form';
// hooks
import useFetch from '../../../hooks/useFetch';

// ----------------------------------------------------------------------
export default function ContactForm({membre}) {
  
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [loc, setLoc] = useState('');
  //   const phoneRegExp =/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const phoneRegExp = '(0||\\+213)[1-9][0-9]{8}';
  const LoginSchema = Yup.object().shape({
    phone:membre?Yup.string()
      : Yup.string()
      .required('Numéro de téléphone obligatoire')
      .matches(phoneRegExp, "Numéro de téléphone n'est pas valide"),
    email:membre?Yup.string(): Yup.string().required('Email obligatoire'),
  });

    
  const clean = (obj) => {
    Object.keys(obj).forEach((propName) =>{
    
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    })
    return obj
  }
  const defaultValues = {
    phone: '',
    email: '',
  };

  const handleClose = () => {
    setOpen(false);
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (values) => {

    
    if(membre){
      
    axios
    .put(`api/contact/${membre}`, clean(values),{headers:{"Content-Type":"application/x-www-form-urlencoded"}} )
    .then((res) => {
      setResponse(res);
      setError(null);
      window.location.reload(false);
    })
    .catch((error) => {
      console.log(error);
      setError(error);
      setResponse(null);
    })
    .finally(() => {
      setOpen(true);
    });
  
  
}else{
    
    axios
      .post('api/contact', values, { mode: 'no-cors' })
      .then((res) => {
        setResponse(res);
        setError(null);
        window.location.reload(false);
      })
      .catch((error) => {
        console.log(error);
        setError(error);
        setResponse(null);
      })
      .finally(() => {
        setOpen(true);
      });
    }
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {error ? (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert variant="filled" onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Contact existe déja.
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert variant="filled" onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Le contact a été ajouté avec succès.
          </Alert>
        </Snackbar>
      )}
      <Stack spacing={3}>
        <RHFTextField name="phone" label="Numéro de téléphone" />
        <RHFTextField name="email" label="Email" />
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Submit
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}

