import { useContext, useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from '@mui/lab';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Iconify from '../../../components/iconify';
import { ColorModeContext } from '../../../theme';
/*

import PaletteIcon from '@mui/icons/Palette';
*/
export default function ToggleTheme() {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  return (
    <>
      <IconButton onClick={colorMode.toggleColorMode}>
        {theme.palette.mode === 'dark' ? (
          <Iconify icon="ic:outline-mode-night" width={25} height={25} />
        ) : (
          <Iconify icon="ic:outline-light-mode" width={25} height={25} />
        )}
      </IconButton>
    </>
  );
}
