import Icon from '@mui/material/Icon';
// component
import SvgColor from '../../../components/svg-color';
import Iconify from '../../../components/iconify'
// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Utilisateurs',
    path: '/user',
    icon: icon('ic_user'),
  },
  // {
  //   title: 'Commande',
  //   path: '/command',
  //   icon: icon('ic_cart'),
  // },
  // {
  //   title: 'user',
  //   path: '/user',
  //   icon: icon('ic_user'),
  //   children:[
  //     {
  //       title: 'Utilisateurs',
  //       path: '/user',
  //       icon: icon('ic_cart'),
  //     },
  //     {
  //       title: 'Clients',
  //       path: '/client',
  //       icon: icon('ic_cart'),
  //     },
  //   ]
  // },
  // {
  //   title: 'produits',
  //   path: '/products',
  //   icon:<Iconify
  //   icon="fluent-mdl2:product-variant"
  // />, 
    
  // },
  {
    title: 'Actualité',
    path: '/blog',
    icon: icon('ic_blog'),
  },
  {
    title: 'categorie',
    path: '/category',
    icon: <Iconify
      icon="carbon:category"
    />,
  },
  {
    title: 'magasins',
    path: '/pos',
    icon: <Iconify
      icon="ic:round-store" 
    />,
  },
  // {
  //   title: 'equipe',
  //   path: '/team',
  //   icon: <Iconify
  //     icon="fluent:people-team-16-filled"
  //   />,
  // },
  // {
  //   title: 'marque / Modèle',
  //   path: '/brand',

  //   icon: <Iconify
  //     icon="tabler:brand-volkswagen"
  //   />,
  //   children:[
  //     {
  //       title: 'Marque',
  //       path: '/brand',
  //     },
  //     {
  //       title: 'Modèle',
  //       path: '/modal',
  //     },
  //   ]
  // },
  {
    title: 'message',
    path: '/message',
    icon: <Iconify
      icon="ic:baseline-message"
    />,
  },
  {
    title: 'question',
    path: '/question',
    icon: <Iconify
      icon="ph:question"
    />,
  },
  {
    title: 'Sections de site',
   

    icon: <Iconify
      icon="material-symbols:web"
    />,
    children:[
      {
        title: 'Héro',
        path: '/hero',  
      },
      {
        title: 'Contact',
        path: '/contact',

      },
      {
        title: 'Directeur',
        path: '/boss',
      },
      {
        title: 'Historique',
        path: '/historique',
      },
      {
        title: 'Témoignage',
        path: '/temoignage',
      },
      {
        title: 'Partenaire',
        path: '/logo',
      },
      {
        title: 'FAQ',
        path: '/faq',
      },
      {
        title: 'Condition d\'utilisation',
        path: '/condition',
      },
      // {
      //   title: 'Actualite',
      //   path: '/blog',
      // },
      {
        title: 'Promotion',
        path: '/promotion',
      },
      {
        title: 'Aide',
        path: '/UserHelp',
      },
    ]
  },

];

export default navConfig;
