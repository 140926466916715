import * as Yup from 'yup';
import { useState } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  Stack,
  Snackbar,
  Alert,
  Box,
  CircularProgress,
  Typography,
  Autocomplete,
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// api
import axios from '../../../api/axios';
// components
import { FormProvider, RHFTextField, RHFFile, RHFSelect, RHFCheckbox, RHFRadio } from '../../../components/hook-form';
// hooks
import useFetch from '../../../hooks/useFetch';

// ----------------------------------------------------------------------

export default function ClientQuoteForm({ membre }) {

  console.log(membre)
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(membre || 'is_text');

  const LoginSchema = Yup.object().shape({
    quote: Yup.string(),
    quote_english: Yup.string(),
    quote_arabic: Yup.string(),
    name: Yup.string().required('Nom de client obligatoire'),
    activity: Yup.string().required('activité de client obligatoire'),
    name_arabic: Yup.string().required('Nom de client obligatoire'),
    activity_arabic: Yup.string().required('activité de client obligatoire'),
    name_english: Yup.string().required('Nom de client obligatoire'),
    activity_english: Yup.string().required('activité de client obligatoire'),
    // type: Yup.string(),
    image: Yup.mixed().notRequired(),
    // .test('type', 'Image extension invalid', (value) => {
    //   return value && (value.type === 'image/png' || value.type === 'image/jpeg' || value.type === 'image/svg+xml')
    //   ||(value.type === 'video/gif' || value.type === 'video/mp4' || value.type === 'video/mov');
    // })
    // .test('fileSize', 'La taille de fichier est trop volumineux', (value) => {
    //   return value && value.size <= 2000000;
    // }),

    image_client: membre
      ? Yup.mixed().notRequired()
      : Yup.mixed()
          .test('fileExist', 'Image obligatoire', (value) => {
            return value && value?.name;
          })
          .test('type', 'Image extension invalid', (value) => {
            return (
              value && (value.type === 'image/png' || value.type === 'image/jpeg' || value.type === 'image/svg+xml')
            );
          })
          .test('fileSize', 'La taille de fichier est trop volumineux', (value) => {
            return value && value.size <= 2000000;
          }),
  });

  const clean = (obj) => {
    Object.keys(obj).forEach((propName) => {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    });
    return obj;
  };
  const defaultValues = {
    quote: membre?.is_text === 1 ? membre.quote : '',
    quote_english: membre?.is_text === 1 ? membre.quote_english : '',
    quote_arabic: membre?.is_text === 1 ? membre.quote_arabic : '',
    name: membre ? membre.name : '',
    activity: membre ? membre.activity : '',
    name_arabic: membre ? membre.name_arabic : '',
    activity_arabic: membre ? membre.activity_arabic : '',
    name_english: membre ? membre.name_english : '',
    activity_english: membre ? membre.activity_english : '',
    image: null,
    image_client: null,
  };

  const handleClose = () => {
    setOpen(false);
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const handleChangeType = (event) => {
    setType(event.target.value);
    console.log(type);
  };
  const onSubmit = async (values) => {
    console.log('hereee');
    console.log(values.type);
    if (type === 'is_text') {
      values.is_text = 1;
      values.is_video = 0;
      values.is_image = 0;
    } else if (type === 'is_image') {
      values.is_text = 0;
      values.is_video = 0;
      values.is_image = 1;
    } else {
      values.is_text = 0;
      values.is_video = 1;
      values.is_image = 0;
    }

    // if(values.banner === true){
    //   values.banner = 1
    // }else{
    //   values.banner = 0
    // }

    // if(values.promotion === true){
    //   values.promotion = 1
    // }else{
    //   values.promotion = 0
    // }
    if (values.is_text === 0 && values.quote === '') {
      values.quote = 'test';
      values.quote_english = 'test';
      values.quote_arabic = 'test';
    }

    // setLoading(true)
    //     axios
    //       .post(`api/clientQuote`, clean(values), { mode: 'no-cors' })
    //       .then((res) => {
    //         setResponse(res);
    //         setError(null);
    //         window.location.reload(false);
    //       })
    //       .catch((error) => {
    //         console.log(error);
    //         setError(error);
    //         setResponse(null);
    //       })
    //       .finally(() => {
    //         setOpen(true);
    //         setLoading(false)
    //       });

    if (membre) {
      if (values.image != null || values.image_client != null) {
        const val = clean({ image: values.image, image_client: values.image_client });
        axios
          .post('api/clientQuoteFile', val, { mode: 'no-cors' })
          .then((res) => {
            setResponse(res);
            setError(null);

            if (values.image) {
              values.quote = res.data[0];
            }
            if (values.image_client) {
              values.image_client = res.data[1];
            }

            // window.location.reload(false);
          })
          .catch((error) => {
            setError(error);
            setResponse(null);
          })
          .finally(() => {
            // setOpen(true);

            axios
              .put(`api/clientQuote/${membre.id}`, clean(values), { headers: { 'Content-Type': 'application/json' } })
              .then((res) => {
                setResponse(res);
                setError(null);
                window.location.reload(false);
              })
              .catch((error) => {
                console.log(error);
                setError(error);
                setResponse(null);
              })
              .finally(() => {
                setOpen(true);
              });
          });
      } else {
        console.log('here');
        axios
          .put(`api/clientQuote/${membre.id}`, clean(values), {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          })
          .then((res) => {
            setResponse(res);
            setError(null);
            window.location.reload(false);
          })
          .catch((error) => {
            console.log(error);
            setError(error);
            setResponse(null);
          })
          .finally(() => {
            setOpen(true);
          });
      }
    } else {
      axios
        .post('api/clientQuote', values, { mode: 'no-cors' })
        .then((res) => {
          setResponse(res);
          setError(null);
          window.location.reload(false);
        })
        .catch((error) => {
          console.log(error);
          setError(error);
          setResponse(null);
        })
        .finally(() => {
          setOpen(true);
        });
    }
  };
  return loading ? (
    <Box m={1} display="flex" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  ) : (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {error ? (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert variant="filled" onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            Témoignage existe déja.
          </Alert>
        </Snackbar>
      ) : (
        <Snackbar
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert variant="filled" onClose={handleClose} severity="success" sx={{ width: '100%' }}>
            Le témoignage a été ajouté avec succès.
          </Alert>
        </Snackbar>
      )}
      <Stack spacing={3}>
        <FormLabel id="demo-row-radio-buttons-group-label">Type</FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          defaultValue={type}
          value={type}
          onChange={handleChangeType}
        >
          <FormControlLabel value="is_text" control={<Radio />} label="Text" />
          <FormControlLabel value="is_video" control={<Radio />} label="Video" />
          <FormControlLabel value="is_image" control={<Radio />} label="Image" />
        </RadioGroup>
        {type === 'is_text' ? (
          <>
            <RHFTextField name="quote" label="Témoignage" />
            <RHFTextField name="quote_english" label="Témoignage anglais" />
            <RHFTextField dir="rtl" name="quote_arabic" label="Témoignage arabe" />
          </>
        ) : type === 'is_image' ? (
          <RHFFile name="image" label="Image témoignage" />
        ) : (
          <RHFFile name="image" label="Video témoignage" />
        )}

        <RHFTextField name="name" label="Nom de client" />
        <RHFTextField name="activity" label="Activité de client" />
        <RHFTextField name="name_arabic" label="Nom de client arabe" />
        <RHFTextField name="activity_arabic" label="Activité de client arabe" />
        <RHFTextField name="name_english" label="Nom de client anglais" />
        <RHFTextField name="activity_english" label="Activité de client anglais" />
        {/* <RHFRadio options={["is_text","is_video","is_image"]} name="type" label="Type" /> */}

        <RHFFile name="image_client" label="Image de client" />
        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Submit
        </LoadingButton>
      </Stack>
    </FormProvider>
  );
}
