import * as React from 'react';

import { Helmet } from 'react-helmet-async';
import { useState } from 'react';
// @mui
import { Box, Card, Stack, Container, Typography, CircularProgress } from '@mui/material';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { CategoryForm } from '../sections/@dashboard/category';
// hooks
import useFetch from '../hooks/useFetch';

export default function CategoryPage() {
  const { data: CategoryList, loading, error } = useFetch('/api/bigCategory');
  const [parent, setParent] = useState({ name: 'categorie' });
  const data = {
    id: 'root',
    name: 'categorie',
    subcategory: [],
  };
  if (CategoryList) {
    data.subcategory = [...CategoryList];
  }
  console.log(CategoryList);
  const renderTree = (nodes) => (
    <TreeItem
      key={nodes.id}
      nodeId={nodes.id.toString()}
      label={nodes.name}
      onClick={() => {
        setParent(nodes);
      }}
    >
      {nodes.subcategory.length > 0 ? nodes.subcategory.map((node) => renderTree(node)) : null}
    </TreeItem>
  );
  return loading ? (
    <Box m={1} display="flex" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  ) : CategoryList ? (
    <>
      <Helmet>
        <title> Catégories </title>
      </Helmet>

      <Container display="flex">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Catégories
          </Typography>
        </Stack>
        <Card sx={{ display: 'flex', height: '100vh', padding: '20px' }}>
          <Scrollbar sx={{ width: '60%' }}>
            <TreeView
              aria-label="rich object"
              defaultCollapseIcon={<Iconify icon="ic:baseline-chevron-right" />}
              defaultExpanded={['root']}
              defaultExpandIcon={<Iconify icon="ic:outline-expand-more" />}
            >
              {renderTree(data)}
            </TreeView>
          </Scrollbar>
          <Box sx={{ width: '40%' }}>
            <CategoryForm parent={parent} />
          </Box>
        </Card>
      </Container>
    </>
  ) : (
    <div>ERROR....</div>
  );
}
