import { Helmet } from 'react-helmet-async';
import {useLocation} from 'react-router-dom';
// material
import {
  Box,
  Stack,
  Container,
  Typography,
  CircularProgress
} from '@mui/material';
// sections
import  LogoForm  from '../../../sections/@dashboard/siteSection/LogoForm';
// hooks
import useFetch from '../../../hooks/useFetch';

// ----------------------------------------------------------------------




function AddLogoPage({route,navigate}) {
  const location = useLocation();
  console.log(location.state.name)
  const { data: Logo, loading, error } = useFetch(`/api/marque`,{params:{marqueId:location.state.name}});
  // console.log(Team);
  return (
    Logo?
    <>
      <Helmet>
        <title> Logos </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            
            {location.state.name? `Modifier ${Logo.name}` :"Ajouter un nouveau logo"}
          </Typography>
        </Stack>
        <LogoForm membre={location.state.name}/>
      </Container>
    </>
    :
    <Box m={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
  );
}

export default AddLogoPage;

