import { Helmet } from 'react-helmet-async';
import {useLocation} from 'react-router-dom';
// material
import {
  Box,
  Stack,
  Container,
  Typography,
  CircularProgress
} from '@mui/material';
// sections
import  RelationForm  from '../../../sections/@dashboard/siteSection/RelationForm';
// hooks
import useFetch from '../../../hooks/useFetch';

// ----------------------------------------------------------------------




function AddRelationPage({route,navigate}) {
  const location = useLocation();
  
  const { data: Logo, loading, error } = useFetch(`/api/marque`,{params:{marqueId:location?.state?.name}});
  const { data: Categories, loading:loagindCategories, error:errorCategories } = useFetch('/api/bigCategory');
  
  return (
    Logo && Categories?
    <>
      <Helmet>
        <title> Logos </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            
            { `Ajouter des catégories à ${Logo.name}`}
          </Typography>
        </Stack>
        <RelationForm membre={Logo.id} categories={Categories}/>
      </Container>
    </>
    :
    <Box m={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
    </Box>
  );
}

export default AddRelationPage;

