import { Navigate, Outlet } from "react-router-dom";
import useAuthContext from "../context/AuthContext";

const GuestLayout = () => {
  const { user,auth } = useAuthContext();

  return !localStorage.getItem("user") ? <Outlet /> : <Navigate to="/" />;
};

export default GuestLayout;
