import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useForm, FormProvider, useFormContext } from 'react-hook-form';
// @mui
import {
  Box,
  Grid,
  Card,
  Badge,
  Stack,
  Alert,
  Button,
  Avatar,
  Snackbar,
  Container,
  Typography,
  TextField,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../../../sections/@dashboard/user';
// hooks
import useFetch from '../../../hooks/useFetch';
// api
import axios from '../../../api/axios';

export default function ContactPage() {
  const theme = useTheme();

  const methods = useForm();
  // const onSubmit = data => console.log(data);

  const navigate = useNavigate();
  let USERLIST = [];
  const { data, loading, error } = useFetch('/api/boss');
  if (data) {
    USERLIST = data;
  }

  const arabicRegex = /^[\u0600-\u06FF\s]*$/;
  
  const [text, setText] = useState(null);
  const [textEnglish, setTextEnglish] = useState(null);
  const [textArabic, setTextArabic] = useState(null);
  const [name, setName] = useState(null);
  const [surname, setSurname] = useState(null);
  const [position, setPosition] = useState(null);
  const [image, setImage] = useState(null);
  const [editText, setEditText] = useState(false);
  const [editTextEnglish, setEditTextEnglish] = useState(false);
  const [editTextArabic, setEditTextArabic] = useState(false);
  const [editName, setEditName] = useState(false);
  const [editPosition, setEditPosition] = useState(false);
  const [editImage, setEditImage] = useState(false);
  const [hoverText, setHoverText] = useState(false);
  const [hoverTextEnglish, setHoverTextEnglish] = useState(false);
  const [hoverTextArabic, setHoverTextArabic] = useState(false);
  const [hoverImage, setHoverImage] = useState(false);
  const [hoverName, setHoverName] = useState(false);
  const [hoverPosition, setHoverPosition] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [res, setRes] = useState(null);
  const [err, setErr] = useState(null);
  const [open, setOpen] = useState(false);

  const clean = (obj) => {
    Object.keys(obj).forEach((propName) => {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    });
    return obj;
  };

  const onSubmit = (data) => {
    setIsSubmitting(true);

    if (image != null) {
      axios
        .post('api/bossFile', { image }, { mode: 'no-cors' })
        .then((res) => {
          setRes(res);
          setErr(null);
          setImage(res.data);
          // console.log(res.data);
          // window.location.reload(false);
        })
        .catch((error) => {
          setErr(error);
          setRes(null);
        })
        .finally(() => {
          const values = clean({  name,  surname,  position,  text, text_english:textEnglish, text_arabic:textArabic });
          values.position = "right"
          values.image = `img/boss/${image.name}`;

          if (USERLIST[0]) {
            axios
              .put(`api/boss/${USERLIST[0].id}`, values, { headers: { 'Content-Type': 'application/json' } })
              .then((res) => {
                setRes(res);
                setErr(null);
                window.location.reload(false);
              })
              .catch((error) => {
                console.log(error);
                setErr(error);
                setRes(null);
              })
              .finally(() => {
                setOpen(true);
              });
          } else {
            
            axios
              .post(`api/boss`, values, { headers: { 'Content-Type': 'application/json' } })
              .then((res) => {
                setRes(res);
                setErr(null);
                window.location.reload(false);
              })
              .catch((error) => {
                console.log(error);
                setErr(error);
                setRes(null);
              })
              .finally(() => {
                setOpen(true);
              });
          }
        });
    } else {
      axios
        .put(`api/boss/${USERLIST[0].id}`, clean({  name,  surname,  position,  text, text_english:textEnglish, text_arabic:textArabic }), {
          headers: { 'Content-Type': 'application/json' },
        })
        .then((res) => {
          setRes(res);
          setErr(null);
          window.location.reload(false);
        })
        .catch((error) => {
          console.log(error);
          setErr(error);
          setRes(null);
        })
        .finally(() => {
          setOpen(true);
        });
    }

    setIsSubmitting(false);
  };

  const handleEditText = () => {
    setEditText(!editText);
  };
  const handleEditTextEnglish = () => {
    setEditTextEnglish(!editTextEnglish);
  };
  const handleEditTextArabic = () => {
    setEditTextArabic(!editTextArabic);
  };
  const handleEditName = () => {
    setEditName(!editName);
  };
  const handleEditPosition = () => {
    setEditPosition(!editPosition);
  };

  const handleMouseEnter = () => {
    setHoverText(true);
  };
  const handleMouseEnterEnglish = () => {
    setHoverTextEnglish(true);
  };
  const handleMouseEnterArabic = () => {
    setHoverTextArabic(true);
  };

  const handleMouseLeave = () => {
    setHoverText(false);
  };
  const handleMouseLeaveEnglish = () => {
    setHoverTextEnglish(false);
  };
  const handleMouseLeaveArabic = () => {
    setHoverTextArabic(false);
  };
  const handleMouseEnterImage = () => {
    setHoverImage(true);
  };

  const handleMouseLeaveImage = () => {
    setHoverImage(false);
  };
  const handleMouseEnterName = () => {
    setHoverName(true);
  };

  const handleMouseLeaveName = () => {
    setHoverName(false);
  };
  const handleMouseEnterPosition = () => {
    setHoverPosition(true);
  };

  const handleMouseLeavePosition = () => {
    setHoverPosition(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleFileChange = (event) => {
    setImage(event.target.files[0]);
    console.log(image);
  };

  return loading ? (
    <Box m={1} display="flex" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  ) : USERLIST ? (
    <>
      <Helmet>
        <title> Mot de directeur </title>
      </Helmet>

      <Container>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {err ? (
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              >
                <Alert variant="filled" onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                  Erreur
                </Alert>
              </Snackbar>
            ) : (
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              >
                <Alert variant="filled" onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                  Modification avec succès.
                </Alert>
              </Snackbar>
            )}
            <Box sx={{ flexGrow: 1, marginBottom: 10 }}>
              <Grid container spacing={2} columns={16}>
                <Grid xs={8}>
                  <Box
                    onMouseEnter={() => handleMouseEnterImage()}
                    onMouseLeave={() => handleMouseLeaveImage()}
                    sx={{
                      width: 350,
                      height: 350,
                      backgroundColor: 'transparent',
                      marginBottom: 3,
                      '&:hover': {
                        backgroundColor: 'transparent',
                        opacity: [0.9, 0.8, 0.7],
                        cursor: 'pointer',
                      },
                    }}
                  >
                    <label htmlFor="file-input">
                      <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        badgeContent={
                          hoverImage ? (
                            <Iconify
                              icon={'eva:edit-fill'}
                              height={70}
                              width={70}
                              sx={{ height: 20, width: 20 }}
                              style={{ color: 'gray' }}
                            />
                          ) : (
                            <></>
                          )
                        }
                      >
                        <input type="file" id="file-input" hidden onChange={handleFileChange} />
                        <Avatar
                          src={image ? URL.createObjectURL(image) : `https://lab.saroussa.com/${USERLIST[0]?.image}`}
                          alt="avatar"
                          sx={{ width: 350, height: 350 }}
                        />
                      </Badge>
                    </label>
                  </Box>
                  <div
                    style={{
                      marginBottom: 20,
                    }}
                  >
                    {editName ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                        }}
                      >
                        <TextField
                          id="name"
                          fullWidth
                          defaultValue={USERLIST[0]?.name}
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                        <TextField
                          id="surname"
                          fullWidth
                          defaultValue={USERLIST[0]?.surname}
                          value={surname}
                          onChange={(e) => {
                            setSurname(e.target.value);
                          }}
                        />
                        <Button variant="text" component="label" color="warning" onClick={() => handleEditName()}>
                          Cancel
                        </Button>
                      </div>
                    ) : (
                      <Typography
                        sx={{
                          '&:hover': {
                            cursor: 'pointer',
                          },
                        }}
                        onClick={() => {
                          handleEditName();
                        }}
                        onMouseEnter={() => {
                          handleMouseEnterName();
                        }}
                        onMouseLeave={() => {
                          handleMouseLeaveName();
                        }}
                      >
                        M. {USERLIST[0]?.name} {USERLIST[0]?.surname}
                        {hoverName ? (
                          <Iconify
                            icon={'eva:edit-fill'}
                            height={70}
                            width={70}
                            sx={{ height: 20, width: 20, ml: 2 }}
                            style={{ color: 'gray' }}
                          />
                        ) : (
                          <></>
                        )}
                      </Typography>
                    )}
                  </div>
                  {editPosition ? (
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <TextField
                        id="position"
                        fullWidth
                        defaultValue={USERLIST[0].position}
                        value={position}
                        onChange={(e) => {
                          setPosition(e.target.value);
                        }}
                      />
                      <Button variant="text" component="label" color="warning" onClick={() => handleEditPosition()}>
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Typography
                      sx={{
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                      onClick={() => {
                        handleEditPosition();
                      }}
                      onMouseEnter={() => {
                        handleMouseEnterPosition();
                      }}
                      onMouseLeave={() => {
                        handleMouseLeavePosition();
                      }}
                    >
                      {USERLIST[0]?.position}
                      {hoverPosition ? (
                        <Iconify
                          icon={'eva:edit-fill'}
                          height={70}
                          width={70}
                          sx={{ height: 20, width: 20, ml: 2 }}
                          style={{ color: 'gray' }}
                        />
                      ) : (
                        <></>
                      )}
                    </Typography>
                  )}
                </Grid>
                <Grid xs={8} spacing={2}>
                  {editText ? (
                    <>
                      <TextField
                        id="outlined-textarea"
                        fullWidth
                        rows={19}
                        multiline
                        defaultValue={USERLIST[0]?.text}
                        value={text}
                        onChange={(e) => {
                          setText(e.target.value);
                        }}
                      />
                      <Stack component="span" direction="row" alignItems="flex-end" justifyContent="flex-end">
                        <Button variant="text" component="label" color="warning" onClick={() => handleEditText()}>
                          Cancel
                        </Button>
                      </Stack>
                    </>
                  ) : (
                    <Card
                      sx={{
                        padding: '20px',
                        backgroundColor: hoverText ? alpha(theme.palette.grey[500], 0.12) : 'white',
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                      onClick={() => handleEditText()}
                      onMouseEnter={() => handleMouseEnter()}
                      onMouseLeave={() => handleMouseLeave()}
                    >
                      <Typography variant="body1" gutterBottom>
                        {USERLIST[0]?.text}
                      </Typography>
                      <Stack component="span" direction="row" alignItems="flex-end" justifyContent="flex-end">
                        {hoverText ? (
                          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} style={{ color: 'gray' }} />
                        ) : (
                          <></>
                        )}
                      </Stack>
                    </Card>
                  )}


                  {editTextEnglish ? (
                    <>
                      <TextField
                        id="outlined-textarea"
                        fullWidth
                        rows={19}
                        multiline
                        defaultValue={USERLIST[0]?.text_english}
                        value={textEnglish}
                        onChange={(e) => {
                          setTextEnglish(e.target.value);
                        }}
                      />
                      <Stack component="span" direction="row" alignItems="flex-end" justifyContent="flex-end">
                        <Button variant="text" component="label" color="warning" onClick={() => handleEditTextEnglish()}>
                          Cancel
                        </Button>
                      </Stack>
                    </>
                  ) : (
                    <Card
                      sx={{
                        padding: '20px',
                        backgroundColor: hoverTextEnglish ? alpha(theme.palette.grey[500], 0.12) : 'white',
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                      onClick={() => handleEditTextEnglish()}
                      onMouseEnter={() => handleMouseEnterEnglish()}
                      onMouseLeave={() => handleMouseLeaveEnglish()}
                    >
                      <Typography variant="body1" gutterBottom>
                        {USERLIST[0]?.text_english}
                      </Typography>
                      <Stack component="span" direction="row" alignItems="flex-end" justifyContent="flex-end">
                        {hoverTextEnglish ? (
                          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} style={{ color: 'gray' }} />
                        ) : (
                          <></>
                        )}
                      </Stack>
                    </Card>
                  )}


                  {editTextArabic ? (
                    <>
                      <TextField
                        dir="rtl"
                        id="outlined-textarea"
                        fullWidth
                        rows={19}
                        multiline
                        defaultValue={USERLIST[0]?.text_arabic}
                        value={textArabic}
                        onChange={(e) => {
                          if (!arabicRegex.test(e.target.value)) {
                            // If not, prevent the input
                            e.preventDefault();
                          }else{
                            setTextArabic(e.target.value);
                          }
                          
                        }}
                        inputProps={{
                          pattern: '^[\\u0600-\\u06FF\\s]*$',
                          title: 'Only Arabic letters and spaces are allowed',
                        }}
                      />
                      <Stack component="span" direction="row" alignItems="flex-end" justifyContent="flex-end">
                        <Button variant="text" component="label" color="warning" onClick={() => handleEditTextArabic()}>
                          Cancel
                        </Button>
                      </Stack>
                    </>
                  ) : (
                    <Card
                      sx={{
                        padding: '20px',
                        backgroundColor: hoverTextArabic ? alpha(theme.palette.grey[500], 0.12) : 'white',
                        '&:hover': {
                          cursor: 'pointer',
                        },
                      }}
                      onClick={() => handleEditTextArabic()}
                      onMouseEnter={() => handleMouseEnterArabic()}
                      onMouseLeave={() => handleMouseLeaveArabic()}
                    >
                      <Typography dir="rtl" variant="body1" gutterBottom>
                        {USERLIST[0]?.text_arabic}
                      </Typography>
                      <Stack component="span" direction="row" alignItems="flex-end" justifyContent="flex-end">
                        {hoverTextArabic ? (
                          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} style={{ color: 'gray' }} />
                        ) : (
                          <></>
                        )}
                      </Stack>
                    </Card>
                  )}

                  {/* <input type="submit" /> */}
                </Grid>
              </Grid>
            </Box>
            <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
              Submit
            </LoadingButton>
          </form>
        </FormProvider>
      </Container>
    </>
  ) : (
    <h1>{error}</h1>
  );
}
