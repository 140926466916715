import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
// material
import { Box, Stack, Container, Typography, CircularProgress } from '@mui/material';
// sections
import ClientQuoteForm from '../../../sections/@dashboard/siteSection/ClientQuoteForm';
// hooks
import useFetch from '../../../hooks/useFetch';

// ----------------------------------------------------------------------

function AddClientQuotePage({ route, navigate }) {
  const location = useLocation();
  console.log(location.state.name);
  const {
    data: Temoignage,
    loading,
    error,
  } = useFetch(`/api/clientQuote`, { params: { clientQuoteId: location.state.name } });
  // console.log(Team);
  return Temoignage ? (
    <>
      <Helmet>
        <title> Témoignage </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {location.state.name ? `Modifier témoignage de ${Temoignage.name}` : 'Ajouter un nouveau témoignage'}
          </Typography>
        </Stack>
        <ClientQuoteForm membre={location.state.name ? Temoignage : null} />
      </Container>
    </>
  ) : (
    <Box m={1} display="flex" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  );
}

export default AddClientQuotePage;
