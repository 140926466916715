import { Helmet } from 'react-helmet-async';
import {useLocation} from 'react-router-dom';
// material
import {
  Box,
  Stack,
  Container,
  Typography,
  CircularProgress
} from '@mui/material';
// sections
import  UserHelpForm  from '../../../sections/@dashboard/siteSection/UserHelpForm';
// hooks
import useFetch from '../../../hooks/useFetch';
// ----------------------------------------------------------------------




function AddUserHelpPage() {
  const location = useLocation();
  const { data: Historique, loading, error } = useFetch(`/api/historique`,{params:{historiqueId:location.state.name}});

  return (
    Historique?
    <>
      <Helmet>
        <title> Aide </title>
      </Helmet>
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            {location.state.name? `Modifier ${Historique.year}` :"Ajouter un nouveau événement"}
          </Typography>
        </Stack>
        <UserHelpForm membre={location.state.name}/>
      </Container>
    </>
    : <Box m={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
  );

}

export default AddUserHelpPage;

