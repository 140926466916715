import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, Button, Typography } from '@mui/material';
import { warning } from '@remix-run/router';
// import Typography from 'src/theme/overrides/Typography';

// ----------------------------------------------------------------------

RHFFile.propTypes = {
  name: PropTypes.string,
};

export default function RHFFile({ name,label, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        // <input
        //     type="file"
            
        //     name={name}
        //     onChange={(e) => field.onChange({ target: { value: e.target.files[0], name: field.name } })}
        //   />
        <>
        <div>
        <Button
          variant="contained"
          component="label"
          color='warning'
          
        >
          Uploader {label}
          <input
            type="file"
            hidden
            name={name}
            onChange={(e) => field.onChange({ target: { value: e.target.files[0], name: field.name } })}
          />
        </Button>
        <Typography
        component="span"
       
        >{field?.value?.name}</Typography>
        </div>
        <Typography
        component="span"
        sx={{
          color: 'red',
          fontSize:13,
        }}
        >{error?.message}</Typography>
       
        </>

      )
      }
    />
    // <Controller 
    //   name={name}
    //   control={control}
    //   render={({ field, fieldState: { error } }) => (
    //     <input
    //       {...field}
    //       name={name} 
    //       type="file" 
    //       // error={!!error}
    //       // helperText={error?.message}
    //       {...other}
    //     />
    //   )}
    // />

  );
}
