import * as Yup from 'yup';
import { useState } from 'react';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useFormContext, Controller } from 'react-hook-form';
// @mui
import { InputLabel, Button, Stack, Snackbar, Alert, IconButton, InputAdornment, Box, CircularProgress, Typography, Autocomplete, TextField, Select, MenuItem, OutlinedInput, Chip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useTheme } from '@mui/material/styles';
// api
import axios from '../../../api/axios';
// components
import Iconify from '../../../components/iconify';
import { FormProvider, RHFTextField, RHFFile, RHFSelect } from '../../../components/hook-form';
// hooks
import useFetch from '../../../hooks/useFetch';
// import Button from 'src/theme/overrides/Button';

// ----------------------------------------------------------------------

function getStyles(name, role, theme) {
    return {
        fontWeight:
            role.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

export default function UserForm({ membre }) {
    const theme = useTheme();
    const rolesList = [
        'Admin', 'User', 'Client'
    ]

    const [showPassword, setShowPassword] = useState(false);
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);
    const [generatedPasword, setGeneratedPassword] = useState(null);
    const [role, setRole] = useState([]);

    // const LoginSchema = Yup.object().shape({
    //   name: Yup.string().required('Nom obligatoire'),
    //   image_url: Yup.mixed()
    // });
    const phoneRegExp = '(0||\\+213)[1-9][0-9]{8}';

    const LoginSchema = Yup.object().shape({
        first_name: membre ? Yup.string() : Yup.string().required('Nom obligatoire'),
        last_name: membre ? Yup.string() : Yup.string().required('Prénom obligatoire'),
        email: membre ? Yup.string().email() : Yup.string().email("E-mail doit etre valide").required('E-mail obligatoire'),
        password: membre ? Yup.string() : Yup.string().required('Mot de passe obligatoire'),
        passwordConfirmation: membre ? Yup.string() : Yup.string().oneOf([Yup.ref('password'), null], 'Les mots de passe doivent correspondre'),
        phone: membre ? Yup.string() : Yup.string().required('Numéro de téléphone obligatoire').matches(phoneRegExp, "Numéro de téléphone n'est pas valide"),
        // roles: membre ? Yup.array() : Yup.array().required('Role obligatoire').min(1, "Role obligatoire."),

    });




    const clean = (obj) => {
        Object.keys(obj).forEach((propName) => {
            if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
                delete obj[propName];
            }
        })
        return obj
    }

    const generatePassword = () => {

        const randomPassword =
            Math.random().toString(36).slice(8) + Math.random().toString(36).slice(8);
        setGeneratedPassword(randomPassword);
        console.log(generatedPasword);
    }

    const defaultValues = {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        passwordConfirmation: '',
        phone: '',
        // roles: [],

    };

    const handleClose = () => {
        setOpen(false);
    };

    const methods = useForm({
        resolver: yupResolver(LoginSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        formState: { isSubmitting },
    } = methods;


    const handleChangeRole = (event) => {
        setRole(typeof event.target.value === 'string' ? event.target.value.split(',') : event.target.value);
        console.log(role);

    }
    const getRole = (role) => {
        const roles = []
        role.forEach((item) => (
            item === 'Admin' ? roles.push('ROLE_ADMIN')
                : item === 'User' ? roles.push('ROLE_USER')
                    : roles.push('ROLE_CLIENT')
        )

        );
        return roles

    }
    const onSubmit = async (values) => {
       

        if (membre) {
            
                axios
                    .put(`api/user/${membre}`, clean(values), { headers: { "Content-Type": "application/x-www-form-urlencoded" } })
                    .then((res) => {
                        setResponse(res);
                        setError(null);
                        window.location.reload(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        setError(error);
                        setResponse(null);
                    })
                    .finally(() => {
                        setOpen(true);
                    });

        } else {

        
        axios
            .post('api/user', {...values,roles:getRole(role)}, { mode: 'no-cors' })
            .then((res) => {
                setResponse(res);
                setError(null);
                window.location.reload(false);
            })
            .catch((error) => {
                console.log(error);
                setError(error);
                setResponse(null);
            })
            .finally(() => {
                setOpen(true);
            });
        }
    }
    
    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {error ? (
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert variant="filled" onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {membre? "Erreur dans la modification de l'utilisateur" : "Utilisateur existe déja."} 
                    </Alert>
                </Snackbar>
            ) : (
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert variant="filled" onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        L'utilisateur a été {membre? "modifié": "ajouté"}  avec succès.
                    </Alert>
                </Snackbar>
            )}
            <Stack spacing={3}>

                <RHFTextField name="first_name" label="Nom" />
                <RHFTextField name="last_name" label="Prénom" />
                <RHFTextField name="email" label="E-mail" />
                <Stack
                    direction="row"
                    alignItems="center"
                    spacing={{
                        xs: 0.5,
                        sm: 1,
                    }}
                >
                    <RHFTextField
                        name="password"
                        label="Mot de passe"
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    {/* <Button
                        onClick={() => generatePassword()}
                    >Générer</Button> */}
                </Stack>
                <RHFTextField
                    name="passwordConfirmation"
                    label="Confirmer le mot de passe"
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
                <RHFTextField name="phone" label="N° téléphone" />
                {/* <RHFTextField name="roles" label="Role" /> */}
                <InputLabel id="demo-multiple-chip-label">Role</InputLabel>
                <Select
                    placeholder="Role"
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={role}
                    onChange={(event) => handleChangeRole(event)}
                    input={<OutlinedInput id="select-multiple-chip"/>}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>
                    )}

                >
                    {rolesList.map((name) => (
                        <MenuItem
                            key={name}
                            value={name}
                            style={getStyles(name, role, theme)}
                        >
                            {name}
                        </MenuItem>
                    ))}
                </Select>

                <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
                    Submit
                </LoadingButton>
            </Stack>
        </FormProvider>
    );
}
